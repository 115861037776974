import React from "react";
import { motion } from "framer-motion";
import "./homePage.css";
import { useInView } from "react-intersection-observer";
import { Camera, Share2, Shield, Video } from "lucide-react";

const FeatureSection = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const features = [
    {
      icon: <Video className="w-6 h-6" />,
      title: "Live Streaming",
      description:
        "Broadcast your events in crystal clear quality with our advanced streaming technology",
    },
    {
      icon: <Camera className="w-6 h-6" />,
      title: "Wedding Gallery",
      description: "Create beautiful galleries to showcase your event memories",
    },

    {
      icon: <Share2 className="w-6 h-6" />,
      title: "Event Sharing",
      description: "Share moments instantly with attendees and social media",
    },

    {
      icon: <Shield className="w-6 h-6" />,
      title: "Secure Access",
      description: "Control who can view and interact with your content",
    },
  ];

  return (
    <section id="features" className="features-section">
      <div className="features-container">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="features-text"
        >
          <motion.h2 variants={itemVariants} className="features-heading">
            Powerful Features
          </motion.h2>
          <motion.p variants={itemVariants} className="features-description">
            Everything you need to create, manage, and share memorable events
          </motion.p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="features-grid"
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="feature-card"
            >
              <div className="feature-icon-wrapper">{feature.icon}</div>
              <div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description-text">
                  {feature.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default FeatureSection;
