import { useEffect, useRef, useState } from "react";
import { RiLiveFill } from "react-icons/ri";
import { useAppDataContext } from "hooks/useappdatacontext";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./global.css";
import styles from "./styles.module.css";
import { Atom } from "react-loading-indicators";

const HLSPlayer = ({ preview }) => {
  // Get video URL from context
  const [loadingView, setLoadingView] = useState(true);
  const { singleEventData } = useAppDataContext();
  const hlsVideoUrl = singleEventData?.live_vidlink;

  // States
  const [error, setError] = useState("");

  // References
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // Player options
  const playerOptions = {
    autoplay: true,
    muted: true,
    controls: true,
    fluid: true, // This will make the video player responsive to the container
    preload: "auto",
    sources: [
      {
        src: hlsVideoUrl,
        type: "application/x-mpegURL",
      },
    ],
  };

  useEffect(() => {
    const initializePlayer = () => {
      if (videoRef.current && !playerRef.current) {
        playerRef.current = videojs(videoRef.current, playerOptions, () => {
          console.log("Player is ready");
        });

        let retryCount = 0; // Initialize retry counter

        // Add error handling
        playerRef.current.on("error", (error) => {
          console.error("Video.js Error:", error);

          if (error.code === 3) {
            // MEDIA_ERR_DECODE
            if (retryCount < 3) {
              setError("An error occurred. Retrying...");
              retryCount++; // Increment retry counter
              setTimeout(() => {
                playerRef.current.src({
                  type: "application/x-mpegURL",
                  src: hlsVideoUrl,
                });
                playerRef.current.play();
              }, 5000);
            } else {
              setError("Event stream is not live.");
            }
          } else {
            if (retryCount < 3) {
              setTimeout(() => {
                playerRef.current.src({
                  type: "application/x-mpegURL",
                  src: hlsVideoUrl,
                });
                playerRef.current.play();
                retryCount++; // Increment retry counter
              }, 2000);
            } else {
              setError("Event stream is not live Yet.");
            }
          }
        });
        setError("");
      }
    };

    // Delay the player initialization to ensure the DOM is fully rendered
    const timeoutId = setTimeout(() => {
      initializePlayer();
      setLoadingView(false);
    }, 10000);

    // Cleanup the player when the component unmounts or URL changes
    return () => {
      clearTimeout(timeoutId);
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [hlsVideoUrl]); // Re-run effect if hlsVideoUrl changes
  return (
    <>
      {loadingView && (
        <div
          style={{
            display: "flex",
            placeItems: "center",
            justifyContent: "center",
            backgroundColor: "#000000",
            width: "60%",
            color: "white",
            height: "100px",
          }}
        >
          <h1 className="font-size" style={{ marginRight: "7px" }}>
            Please wait for
          </h1>
          <RiLiveFill className="font-size" style={{ marginRight: "7px" }} />
          <Atom
            style={{ marginLeft: "7px" }}
            variant="spokes"
            color="#ffffff"
            // size="medium"
            className="loader"
            size="small"
            speedPlus="2"
            text=""
            textColor=""
          />
        </div>
      )}
      <div data-vjs-player style={{ display: loadingView ? "none" : "flex" }}>
        <video
          ref={videoRef}
          className={`video-js vjs-default-skin ${styles.hlsPlayerStyles}`}
          controls
          preload="auto"
        />
        {error && <div className={styles.errorText}>{error}</div>}
      </div>
    </>
  );
};
export default HLSPlayer;
