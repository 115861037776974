import React, { useRef } from "react";
import classNames from "classnames";
import styles from "./fileUploadInput.module.css";
import useDragDropHook from "hooks/usedragdrophook";

const FileUploadInput = (props) => {
  // props:
  const {
    label,
    setSelectedFile,
    accept,
    image,
    multiple,
    customLabelStyle,
    customUploadIconStyle,
  } = props;

  // refs:
  const fileInputRef = useRef(null);

  // hook for the drag and drop functionality
  const { dragOverProps } = useDragDropHook((file) => setSelectedFile(file));

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFile(multiple ? files : files[0]);
  };

  const handleUploadFile = (event) => {
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div {...dragOverProps}>
      <input
        type="file"
        onChange={handleFileChange}
        className={styles.inputStyle}
        id="fileInput"
        accept={accept}
        ref={fileInputRef}
        multiple={multiple}
      />
      {label ? (
        <label
          htmlFor="fileInput"
          className={classNames(styles.labelTextStyle, customLabelStyle)}
          onClick={handleUploadFile}
        >
          {label}
        </label>
      ) : (
        <div
          className={classNames(
            styles.uploadIconBlockStyle,
            customUploadIconStyle
          )}
          onClick={handleUploadFile}
        >
          <div className={styles.uploadIconStyle}>
            <img
              src={image}
              alt="uploadedImage"
              className={styles.imageStyle}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadInput;
