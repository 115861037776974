import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_MEDIA_URL}/api` || REACT_APP_BASE_URL,
});

// Add request interceptor
axiosInstance.interceptors.request.use(
  (request) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

const enp = window.location.pathname; // This gives you only the path, excluding query params and fragments
if (enp !=="/login"){
// Add response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/jwt/refresh/`,
          { refresh: refreshToken }
        );

        const { access } = response.data;
        localStorage.setItem("accessToken", access);

        // Update the headers with the new access token
        axios.defaults.headers.common.Authorization = `Bearer ${access}`;
        originalRequest.headers.Authorization = `Bearer ${access}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(
      (error.response && error.response.data) || {
        message: "Something went wrong",
      }
    );
  }
);
}
export default axiosInstance;

