import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  color: "#fff",
  zIndex: theme.zIndex.drawer + 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
}));

const CustomMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: "center",
  maxWidth: "80%",
  fontSize: "1.1rem",
  fontWeight: "bold",
  color: "#e0e0e0",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    maxWidth: "90%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
}));

export default function BackDropLoader(props) {
  const { open, eveCre } = props;

  return (
    <CustomBackdrop open={open}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="inherit" size={60} thickness={5} />
        {eveCre && (
          <CustomMessage>
            Event creation is in progress. Please keep this window open and do not close it. This may take a few moments.
          </CustomMessage>
        )}
      </Box>
    </CustomBackdrop>
  );
}
