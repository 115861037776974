import DashboardIcon from "@mui/icons-material/Dashboard";
import CelebrationIcon from "@mui/icons-material/Celebration";
import SettingsIcon from "@mui/icons-material/Settings";
import { Payments } from "@mui/icons-material";

export const routeNames = {
  login: "/login",
  signup: "/signup",
  dashBoard: "/dashboard",
  event: "/event",
  payments: "/payments",
  settings: "/settings",
  weddingTemplate: "/weddingTemplate",
  editEvent: "/edit-event",
};

export const menuData = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    route: routeNames.dashBoard,
  },

  {
    name: "Create event",
    icon: <CelebrationIcon />,
    route: routeNames.event,
  },

  {
    name: "Pricing",
    icon: <Payments />,
    route: routeNames.payments,
  },

  {
    name: "Settings",
    icon: <SettingsIcon />,
    route: routeNames.settings,
  },
];

export const templateData = ["Template1", "Template2", "Template3"];
