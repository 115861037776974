import EventsForm from "components/eventsForm/page";
const Events = () => {
  return (
    <div>
      <EventsForm />
    </div>
  );
};

export default Events;
