import { createContext, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routeNames } from "constants";
import {
  allChannelsApi,
  allRegionsApi,
  createChannelApi,
  creditsApi,
  deleteChannelApi,
  deleteEventDataApi,
  eventDataApi,
  getAllEventsApi,
  pricingDataApi,
  updateCustomHls,
  updateEventDataApi,
} from "networking/apis/users";
import { useToastContext } from "hooks/usetoastcontext";
import axios from "axios";

const AppDataContext = createContext();
const fonts = [
  "Dancing Script",
  "Great Vibes",
  "Sacramento",
  "Lobster",
  "Alex Brush",
  "Pacifico",
  "Allura",
  "Satisfy",
  "Pinyon Script",
  "Cookie",
  "Rochester",
  "Rosarivo",
  "Fredericka the Great",
  "Cedarville Cursive",
  "Clicker Script",
  "French Script MT",
  "Handlee",
  "Berkshire Swash",
  "Playball",
  "Niconne",
  "Zeyada",
  "Alfa Slab One",
  "Abril Fatface",
  "Baloo 2",
  "Baskervville",
  "Parisienne",
  "Merienda",
  "Italiana",
  "Sirin Stencil",
  "Allerta Stencil",
  "Titan One",
  "Gloock",
  "Belgrano",
  "Kalam",
  "Sofia",
  "Marck Script",
  "Vampiro One",
  "Srisakdi",
  "Pattaya",
  "Carme",
  "Roboto Slab",
  "Julius Sans One",
  "Noto Serif",
  "Merriweather",
  "Droid Serif",
  "Righteous",
  "Rufina",
];

const AppDataProvider = (props) => {
  const navigate = useNavigate();

  // contexts
  const { showToast } = useToastContext();

  // states:
  const [eventData, setEventData] = useState({
    name: "",
    contact: "",
    password: "",
    title: "",
    venue: "",
    fontStyle: fonts[0],
    venue_date: null,
    venue_time: null,
    live_vidlink: "",
    banner: null,
    invitation_card: null,
    invitation_video: null,
    offline_video: null,
    images: [],
    is_secure: "",
    // password: "",
    template: "",
    template_desc: "",
  });

  const [sendCredits, setSendCredits] = useState(false);

  // console.log("data", eventData);

  const resetEventData = () => {
    setEventData({
      ...eventData,
      name: "",
      nameFontFamily: "",
      nameFontSize: "",
      nameColor: "",
      nameFontWeight: "normal",
      contact: "",
      password: "",
      title: "",
      venue: "",
      fontStyle: fonts[0],
      venue_date: null,
      venue_time: null,
      live_vidlink: "",
      banner: "",
      invitation_card: "",
      invitation_video: "",
      offline_video: "",
      images: [] || null,
      is_secure: "",
      // password: "",
      template: "",
      template_desc: "",
    });
  };

  const [registrationFormData, setRegistrationFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    re_password: "",
    role: 1,
  });

  const resetRegistrationFormData = () => {
    setRegistrationFormData({
      ...registrationFormData,
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      re_password: "",
    });
  };

  const [allEventsData, setAllEventsData] = useState([]);
  const [singleEventData, setSingleEventData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [evedisabled, setEveDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [step, setStep] = useState(1);

  const [hlsVideoUrl, setHlsVideoUrl] = useState("");

  const [regions, setRegions] = useState([]);

  const [channelData, setChannelData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [userCredits, setUserCredits] = useState([]);

  const [allChannelsData, setAllChannelsData] = useState([]);

  const [singleChannelData, setSingleChannelData] = useState(null);

  const [pricingData, setPricingData] = useState([]);

  const [showStreamType, setShowStreamType] = useState(false);

  const [feedbackData, setFeedbackData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // funtion to post event data
  const postEventData = useCallback(async (data, videoData) => {
    try {
      setShowLoader(true);
      setEveDisabled(true);
      const response = await eventDataApi(data);
      
      if (response.status === 200 && response.statusText === "OK") {
        if (videoData.has("invitation_video")) {  // Check if invitation_video is present
          const inviVideoUploaded = await uploadFileInChunks(
            videoData.get("invitation_video"),
            `${process.env.REACT_APP_MEDIA_URL}/api/events/inviVideo_chunk/`,
            response.data.prlink
          );
          if (inviVideoUploaded) {
            console.log("Invitation video uploaded successfully");
          }
        }
    
        if (videoData.has("offline_video")) {  // Check if offline_video is present
          const offlineVideoUploaded = await uploadFileInChunks(
            videoData.get("offline_video"),
            `${process.env.REACT_APP_MEDIA_URL}/api/events/offline_chunk/`,
            response.data.prlink
          );
          if (offlineVideoUploaded) {
            console.log("Offline video uploaded successfully");
          }
        }
    
        setShowLoader(false);
        showToast.success("Event created successfully");
        resetEventData();
        setChannelData([]);
        getUserCredits();
        setEveDisabled(false);
        navigate(routeNames.dashBoard);
      } else {
        setShowLoader(false);
        resetEventData();
        setEveDisabled(false);
      }
    } catch (error) {
      setShowLoader(false);
      setEveDisabled(false);
      console.error("Error in postEventData:", error);  // Improved error logging
    }
  }, []);
  
  const uploadFileInChunks = async (file, apiUrl, prlink) => {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size, adjust as needed
    if (!file) return false;
  
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let chunkIndex = 0;
    const fileName = file.name;
    try {
      // Start uploading chunks
      while (chunkIndex < totalChunks) {
        const chunkStart = chunkIndex * CHUNK_SIZE;
        const chunkEnd = Math.min(chunkStart + CHUNK_SIZE, file.size);
        const chunk = file.slice(chunkStart, chunkEnd);
  
        const formData = new FormData();
        formData.append("file", chunk); // Append the chunk of the file
        formData.append("chunkIndex", chunkIndex);
        formData.append("totalChunks", totalChunks);
        formData.append("fileName", fileName);
        formData.append("prlink", prlink); // Assuming this is a necessary field
  
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  
        if (response.status !== 200) {
          throw new Error(`Chunk upload failed: ${chunkIndex + 1}`);
        }
  
        console.log(`Chunk ${chunkIndex + 1} of ${fileName} uploaded successfully`);
        chunkIndex++;
      }
  
      // After all chunks are uploaded successfully
      return chunkIndex >= totalChunks;
    } catch (error) {
      console.error("Error uploading chunk:", error);
      return false; // Return false if any chunk fails
    }
  };
  // funtion to edit event data
  const updateEventData = useCallback(async (data) => {
    try {
      setShowLoader(true);
      const response = await updateEventDataApi(data);
      // console.log("see error", response);
      if (response.status === 200) {
        setShowLoader(false);
        showToast.success("Event updated successfully");
        resetEventData();
      } else {
        showToast.error("Got Error Please Try Again");
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      showToast.error(error.message);
      // console.log("error", error);
    }
  }, []);

  // funtion to delete event data

  const deleteEventData = useCallback(async (id) => {
    try {
      setShowLoader(true);
      const response = await deleteEventDataApi(id);
      if (response.status === 200) {
        setShowLoader(false);
        showToast.success("Event deleted successfully");
        setAllEventsData((prevEvents) =>
          prevEvents.filter((event) => event.id !== id)
        );
        setOpenDelete(false);
      } else {
        setShowLoader(false);
        setOpenDelete(false);
      }
    } catch (error) {
      setShowLoader(false);
      setOpenDelete(false);
      // console.log("Error deleting event:", error);
    }
  }, []);

  // funtion to get all events
  const getAllEvents = useCallback(async () => {
    try {
      setShowLoader(true);
      const response = await getAllEventsApi();
      if (response.status === 200) {
        setAllEventsData(response.data);
        // console.log(response.data);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      // console.log("error", error);
    }
  }, []);

  // funtion to get single event
  const getEvent = useCallback(async (title, id) => {
    try {
      setShowLoader(true);
      const urlToGetEventData = `${process.env.REACT_APP_BASE_URL}/events/get_event/`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prlink: `${title}/${id}`,
        }),
      };
      const response = await fetch(urlToGetEventData, options);
      if (response.status === 200) {
        const responseData = await response.json();
        setSingleEventData(responseData);
        setShowLoader(false);
        return responseData;
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  }, []);

  // function to get all regions
  const fetchRegions = useCallback(async () => {
    try {
      const res = await allRegionsApi();
      setRegions(res.data);
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  }, []);

  // function to create channel
  const handleProceed = useCallback(async (payload) => {
    try {
      setLoading(true);
      const response = await createChannelApi(payload);
      if (response.status === 200) {
        setChannelData(response);
        setLoading(false);
        setShowStreamType(true);
        return true;
      } else {
        setLoading(false);
        setShowStreamType(false);
        return false;
      }
    } catch (error) {
      setLoading(false);
      setShowStreamType(false);
      return false;
    }
  }, []);

  // function to get credits
  const getUserCredits = useCallback(async () => {
    try {
      setShowLoader(true);
      const response = await creditsApi();
      if (response.status === 200) {
        setUserCredits(response.data);
        setShowLoader(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  // function to get all channels data
  const getAllChannelsApi = useCallback(async () => {
    try {
      setShowLoader(true);
      const response = await allChannelsApi();
      if (response.status === 200) {
        setShowLoader(false);
        setAllChannelsData(response.data);
      } else {
        setShowLoader(false);
        // console.log("Error fetching all channels data");
      }
    } catch (error) {
      setShowLoader(false);
      // console.log("Cant't able to fetch all channels data", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // function to delete channel
  const deleteChannel = useCallback(async (id) => {
    try {
      const response = await deleteChannelApi(id);
      if (response.status === 200) {
        showToast.success("Channel deleted");
      }
    } catch (error) {
      // console.log("Can,t able to delete channel", error);
    }
  }, []);

  // function to get single channel data

  const path = useParams();

  const getChannelData = async (id) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stream/get_event_channel/`;
      const response = await axios.post(
        url,
        { prlink: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // Check if response status is OK
      if (response && response.status === 200 && response.data) {
        setSingleChannelData(response.data);
      } else {
        setSingleChannelData(null);
      }
    } catch (error) {
      setSingleChannelData(null);
      console.error("Error fetching channel data:", error.message || error);
    }
  };

  // function to get pricing data
  const getPricingData = useCallback(async () => {
    try {
      setShowLoader(true);
      const response = await pricingDataApi();
      if (response.status === 200) {
        setPricingData(response.data);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      // console.log("Error fetching price data", error);
    }
  }, []);

  // function to post user feedback data

  // function to update custom hls
  const updateCustomHlsApi = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await updateCustomHls(id);
      if (response.status === 200) {
        setLoading(false);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      setLoading(false);
      return false;
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      // values:
      sendCredits,
      eventData,
      registrationFormData,
      allEventsData,
      singleEventData,
      regions,
      channelData,
      loading,
      userCredits,
      allChannelsData,
      singleChannelData,
      pricingData,
      feedbackData,
      showLoader,
      step,
      openDelete,
      hlsVideoUrl,
      showStreamType,
      evedisabled,

      // functions:
      setSendCredits,
      setEventData,
      setRegistrationFormData,
      resetRegistrationFormData,
      setAllEventsData,
      setSingleEventData,
      setRegions,
      setLoading,
      setUserCredits,
      setAllChannelsData,
      setSingleChannelData,
      setPricingData,
      setFeedbackData,
      setShowLoader,
      setStep,
      postEventData,
      updateEventData,
      deleteEventData,
      getAllEvents,
      getEvent,
      fetchRegions,
      setChannelData,
      getAllChannelsApi,
      getChannelData,
      getPricingData,
      handleProceed,
      getUserCredits,
      deleteChannel,
      setOpenDelete,
      setHlsVideoUrl,
      setShowStreamType,
      updateCustomHlsApi,
      setEveDisabled,
    }),
    [
      //values:
      sendCredits,
      eventData,
      registrationFormData,
      allEventsData,
      singleEventData,
      regions,
      channelData,
      loading,
      userCredits,
      allChannelsData,
      singleChannelData,
      pricingData,
      feedbackData,
      showLoader,
      step,
      openDelete,
      hlsVideoUrl,
      showStreamType,
      evedisabled,

      //functions:
      setSendCredits,
      setEventData,
      setRegistrationFormData,
      resetRegistrationFormData,
      setAllEventsData,
      setSingleEventData,
      setRegions,
      setLoading,
      setUserCredits,
      setAllChannelsData,
      setSingleChannelData,
      setPricingData,
      setFeedbackData,
      setShowLoader,
      setStep,
      postEventData,
      updateEventData,
      deleteEventData,
      getAllEvents,
      getEvent,
      fetchRegions,
      setChannelData,
      getAllChannelsApi,
      getChannelData,
      getPricingData,
      handleProceed,
      getUserCredits,
      deleteChannel,
      setOpenDelete,
      setHlsVideoUrl,
      setShowStreamType,
      updateCustomHlsApi,
      setEveDisabled,
    ]
  );

  return (
    <AppDataContext.Provider value={memoizedValue}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export { AppDataContext, AppDataProvider };
