import { useContext } from "react";
import { AppDataContext } from "providers/appdataprovider";

export const useAppDataContext = () => {
  const context = useContext(AppDataContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppDataProvider");
  }

  return context;
};
