import React, { useState, useEffect } from "react";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { SlHome } from "react-icons/sl";

import { routeNames } from "constants";
import {
  emailValidationSchema,
  phoneNumberValidationSchema,
} from "validations";
import { useAppDataContext } from "hooks/useappdatacontext";
import { useAuthContext } from "hooks/useauthcontext";
import "./global.css";
import styles from "./signupStyles.module.css";
import axios from "axios";
const Signup = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(true);
  const navigate = useNavigate(); // Use the hook for navigation
  const {
    showLoader,
    setShowLoader,
    registrationFormData,
    setRegistrationFormData,
    step,
    setStep,
  } = useAppDataContext();

  const { userRegister } = useAuthContext();

  const [errorMsg, setErrorMsg] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    re_password: "",
    apiErrorMsg: "",
  });
  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/jwt/verify/`, { token })
        .then((res) => {
          if (res.status === 200) {
            navigate("/dashboard");
          } else {
            navigate("/signup");
          }
        })
        .catch((error) => {
          console.error("Token verification failed:", error);
          navigate("/signup");
        });
    }
  }, [navigate]);

  const handleValidation = () => {
    const newErrorMessages = {};

    if (step === 1) {
      if (!registrationFormData.first_name.trim()) {
        newErrorMessages.first_name = "First name required";
      }

      if (!registrationFormData.last_name.trim()) {
        newErrorMessages.last_name = "Last name required";
      }

      if (!registrationFormData.phone_number.trim()) {
        newErrorMessages.phone_number = "Phone number required";
      } else if (
        !phoneNumberValidationSchema.test(registrationFormData.phone_number)
      )
        newErrorMessages.phone_number =
          "Phone number must be a 10-digit number";
    }

    if (step === 2) {
      if (!registrationFormData.email.trim()) {
        newErrorMessages.email = "Email required";
      } else if (!emailValidationSchema.test(registrationFormData.email)) {
        newErrorMessages.email = "Invalid email format";
      }

      if (!registrationFormData.password.trim()) {
        newErrorMessages.password = "Password required";
      }

      if (!registrationFormData.re_password.trim()) {
        newErrorMessages.re_password = "Confirm password required";
      } else if (registrationFormData.password.length < 8) {
        newErrorMessages.password = "Password must be at least 8 characters";
      }
      if (registrationFormData.password !== registrationFormData.re_password) {
        newErrorMessages.re_password = "Passwords do not match";
      }
    }

    setErrorMsg(newErrorMessages);

    if (Object.keys(newErrorMessages).length === 0) {
      if (step === 2) {
        handleSignUp(registrationFormData);
      } else {
        setStep(2);
      }
    }
  };

  const handleSignUp = async (data) => {
    setShowLoader(true);
    await userRegister(data);
    setShowLoader(false);
  };

  // const renderTopSection = () => {
  //   return <h1 className={styles.titleStyle}>Infinitum Tech</h1>;
  // };

  const renderBottomSection = () => {
    return (
      <section className={styles.bottomBlockStyle}>
        {/* {renderLeftSection()} */}
        {renderRightSection()}
      </section>
    );
  };

  const renderLeftSection = () => {
    return (
      // <div className={styles.imageBlockStyle}>
      //   <img
      //     src={EventsBgImg}
      //     alt="eventsBgImg"
      //     className={styles.imageStyle}
      //   />
      // </div>
      <h1 className={styles.eventsTitleStyle}>Events On Life</h1>
    );
  };

  const renderRightSection = () => {
    return (
      <section className={styles.rightBlockStyle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <button
            onClick={() => {
              navigate("/");
            }}
            className={styles.backButton}
          >
            <SlHome stylw={{ width: "30px" }} />
          </button>

          {/* <h5
            className={styles.loginTextStyle}
            style={{ position: "absolute", left: "40%", flexGrow: 1 }}
          >
            Sign Up
          </h5> */}
          <img
            src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
            style={{
              width: "200px",
              position: "absolute",
              left: "29%",
              flexGrow: 1,
            }}
          />
        </div>

        {step === 1 ? (
          <article className={styles.loginInfoStyle}>
            <p style={{ margin: "auto" }} className={styles.loginSubTextStyle}>
              Please signup to continue
            </p>
          </article>
        ) : (
          <div className={styles.backViewStytle}>
            <KeyboardBackspaceIcon
              className={styles.leftArrowImgStyle}
              onClick={() => setStep(1)}
            />
            <h5 className={styles.backTextStyle}>Back</h5>
          </div>
        )}
        <div className={styles.inputBlockStyle}>
          {step === 1 && renderUserDetailsSection()}
          {step === 2 && renderUserCredentialSection()}
          {step === 1 && (
            <div className={styles.signUpPromptStyle}>
              <p className={styles.loginPromptTextStyle}>
                Already have an account ?
              </p>
              <p
                onClick={() => navigate(routeNames.login)}
                className={styles.loginPromptSubTextStyle}
              >
                Login
              </p>
            </div>
          )}

          {showLoader ? (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleValidation()}
              className={styles.loginBtnStyle}
            >
              {step === 1 ? "Next" : "Signup"}
            </Button>
          )}
        </div>
      </section>
    );
  };

  const renderUserDetailsSection = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-flexible"
          label="First Name"
          type="text"
          value={registrationFormData.first_name}
          onChange={(e) =>
            setRegistrationFormData({
              ...registrationFormData,
              first_name: e.target.value,
            })
          }
          onFocus={() =>
            setErrorMsg({
              ...errorMsg,
              first_name: "",
            })
          }
          helperText={errorMsg.first_name}
          error={!!errorMsg.first_name}
          className={styles.inputStyle}
        />
        <TextField
          id="outlined-multiline-flexible"
          label="Last Name"
          type="text"
          value={registrationFormData.last_name}
          onChange={(e) =>
            setRegistrationFormData({
              ...registrationFormData,
              last_name: e.target.value,
            })
          }
          onFocus={() =>
            setErrorMsg({
              ...errorMsg,
              last_name: "",
            })
          }
          helperText={errorMsg.last_name}
          error={!!errorMsg.last_name}
          className={styles.inputStyle}
        />

        <div>
          <PhoneInput
            country={"in"}
            value={registrationFormData.phone_number}
            onChange={(value) =>
              setRegistrationFormData({
                ...registrationFormData,
                phone_number: value,
              })
            }
            onFocus={() =>
              setErrorMsg({
                ...errorMsg,
                phone_number: "",
              })
            }
          />
          {errorMsg.phone_number && (
            <p style={{ color: "red" }}>{errorMsg.phone_number}</p>
          )}
        </div>
      </>
    );
  };

  const renderUserCredentialSection = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-flexible"
          label="Email"
          type="text"
          value={registrationFormData.email}
          onChange={(e) =>
            setRegistrationFormData({
              ...registrationFormData,
              email: e.target.value,
            })
          }
          onFocus={() =>
            setErrorMsg({
              ...errorMsg,
              email: "",
            })
          }
          helperText={errorMsg.email}
          error={!!errorMsg.email}
          className={styles.inputStyle}
        />
        <div>
          <div
            style={{
              position: "relative",
              marginBottom: 20,
            }}
          >
            <TextField
              id="outlined-password"
              label="Password"
              type={!passwordVisibility ? "text" : "password"}
              value={registrationFormData.password}
              onChange={(e) =>
                setRegistrationFormData({
                  ...registrationFormData,
                  password: e.target.value,
                })
              }
              onFocus={() =>
                setErrorMsg({
                  ...errorMsg,
                  password: "",
                })
              }
              helperText={errorMsg.password}
              error={!!errorMsg.password}
              style={{ flex: 1, marginRight: 10 }} // Added margin for spacing
            />
            <button
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => {
                setPasswordVisibility(!passwordVisibility);
              }}
            >
              {passwordVisibility ? (
                <MdVisibility size={25} />
              ) : (
                <MdVisibilityOff size={25} />
              )}
            </button>
          </div>

          <div
            style={{
              position: "relative",
            }}
          >
            <TextField
              id="outlined-confirm-password"
              label="Confirm Password"
              type={!confirmPasswordVisibility ? "text" : "password"}
              value={registrationFormData.re_password}
              onChange={(e) =>
                setRegistrationFormData({
                  ...registrationFormData,
                  re_password: e.target.value,
                })
              }
              onFocus={() =>
                setErrorMsg({
                  ...errorMsg,
                  re_password: "",
                })
              }
              helperText={errorMsg.re_password}
              error={!!errorMsg.re_password}
              style={{ flex: 1, marginRight: 10 }} // Added margin for spacing
            />
            <button
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => {
                setConfirmPasswordVisibility(!confirmPasswordVisibility);
              }}
            >
              {confirmPasswordVisibility ? (
                <MdVisibility size={25} />
              ) : (
                <MdVisibilityOff size={25} />
              )}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <main className={styles.mainContainerStyle}>
      {/* {renderTopSection()} */}
      {renderBottomSection()}
      <ToastContainer />
    </main>
  );
};

export default Signup;
