import React, { useCallback, useEffect, useState } from "react";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { FcGoogle } from "react-icons/fc";
import { useAppDataContext } from "hooks/useappdatacontext";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useauthcontext";
import { useToastContext } from "hooks/usetoastcontext";
import { emailValidationSchema } from "validations";
import { routeNames } from "constants";
import "./global.css";
import styles from "./LoginStyles.module.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { SlHome } from "react-icons/sl";

const Login = () => {
  const navigate = useNavigate();

  const { showLoader, setShowLoader } = useAppDataContext();
  const { userLogin, loginAuthError, setLoginAuthError } = useAuthContext();
  const { showToast } = useToastContext();
  const [passwordVisibilty, setPasswordVisibility] = useState(true);
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [loginErrorMsg, setLoginErrorMsg] = useState({
    email: "",
    password: "",
    apiErrorMsg: "",
  });

  const [googleUserData, setGoogleUserData] = useState({
    accessToken: "",
    refreshToken: "",
    user: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailForReset, setEmailForReset] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [isResetLoading, setIsResetLoading] = useState(false);

  const handleValidation = () => {
    const newLoginFormErrors = {};

    if (!loginFormData.email.toLowerCase().trim()) {
      newLoginFormErrors.email = "Email required";
    } else if (!emailValidationSchema.test(loginFormData.email)) {
      newLoginFormErrors.email = "Invalid email format";
    }

    if (!loginFormData.password.toLowerCase().trim()) {
      newLoginFormErrors.password = "Password required";
    } else if (loginFormData.password.length < 8) {
      newLoginFormErrors.password = "Password must be at least 8 characters";
    }
    if (Object.keys(newLoginFormErrors).length > 0) {
      setLoginErrorMsg(newLoginFormErrors);
    } else {
      handleLogin(loginFormData);
    }
  };

  const handleLogin = async (data) => {
    setShowLoader(true);
    await userLogin(data);
    setShowLoader(false);
    // setLoginErrorMsg({
    //   email: "",
    //   password: "",
    //   apiErrorMsg: "",
    // });
  };

  const handleResetPassword = async () => {
    try {
      setIsResetLoading(true);
      setResetMessage("");
      const response = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/users/reset_password/`,
        { email: emailForReset }
      );

      if (response.status === 204) {
        showToast.success("Reset link sent! Please check your email.");
        // setResetMessage("Reset link sent! Please check your email.");
        setIsResetLoading(false);
        setEmailForReset("");
        setIsModalOpen(false);
      } else {
        setIsResetLoading(false);
        setResetMessage(response.data.email || "Unexpected response.");
      }
    } catch (error) {
      setIsResetLoading(false);
      setResetMessage(error?.response?.email || "Error sending reset link.");
    }
  };

  const renderBottomSection = () => {
    return (
      <section className={styles.bottomBlockStyle}>
        {/* {renderLeftSection()} */}
        {renderRightSection()}
      </section>
    );
  };

  const renderLeftSection = () => {
    return (
      // <div className={styles.imageBlockStyle}>
      //   <img
      //     src={EventsBgImg}
      //     alt="eventsBgImg"
      //     className={styles.imageStyle}
      //   />
      // </div>

      <h1 className={styles.eventsTitleStyle}>Events On Life</h1>
    );
  };

  const renderRightSection = () => {
    return (
      <section className={styles.rightBlockStyle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <button
            onClick={() => {
              navigate("/");
            }}
            className={styles.backButton}
          >
            <SlHome stylw={{ width: "30px" }} />
          </button>

          {/* <h5
            style={{ position: "absolute", left: "40%", flexGrow: 1 }}
            className={styles.loginTextStyle}
          >
            Login
          </h5> */}
          <img
            src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
            style={{
              width: "200px",
              position: "absolute",
              left: "29%",
              flexGrow: 1,
            }}
          />
        </div>
        <article className={styles.loginInfoStyle}>
          <p style={{ margin: "auto" }} className={styles.loginSubTextStyle}>
            Please login to continue
          </p>
        </article>
        <div className={styles.inputBlockStyle}>
          <TextField
            id="outlined-email"
            label="Email"
            type="text"
            value={loginFormData.email}
            onChange={(e) =>
              setLoginFormData({
                ...loginFormData,
                email: e.target.value,
              })
            }
            onFocus={() => {
              setLoginErrorMsg({
                ...loginErrorMsg,
                email: "",
              });
              setLoginAuthError("");
            }}
            helperText={loginErrorMsg.email}
            error={!!loginErrorMsg.email}
            className={styles.inputStyle}
          />
          <div
            style={{
              position: "relative",
            }}
          >
            <TextField
              id="outlined-password"
              label="Password"
              type={!passwordVisibilty ? "text" : "password"}
              value={loginFormData.password}
              onChange={(e) =>
                setLoginFormData({
                  ...loginFormData,
                  password: e.target.value,
                })
              }
              onFocus={() => {
                setLoginErrorMsg({
                  ...loginErrorMsg,
                  password: "",
                });
                setLoginAuthError("");
              }}
              helperText={loginErrorMsg.password}
              error={!!loginErrorMsg.password}
              style={{ flex: 1, marginRight: 10 }}
            />
            <button
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => {
                setPasswordVisibility(!passwordVisibilty);
              }}
            >
              {passwordVisibilty ? (
                <MdVisibility size={25} />
              ) : (
                <MdVisibilityOff size={25} />
              )}
            </button>
          </div>

          {loginAuthError && (
            <p className={styles.loginAuthErrorStyle}>{loginAuthError}</p>
          )}
          <Link
            onClick={() => setIsModalOpen(true)}
            className={styles.forgetPasswordStyle}
          >
            Forgot password ?
          </Link>

          {showLoader ? (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              onClick={handleValidation}
              className={styles.loginBtnStyle}
            >
              Login
            </Button>
          )}

          <p className={styles.loginPromptTextStyle}>
            Don't have an account? &nbsp;
            <span
              onClick={() => navigate(routeNames.signup)}
              className={styles.loginPromptSubTextStyle}
              style={{ cursor: "pointer", color: "blue" }}
            >
              Signup
            </span>
          </p>

          <div className={styles.orBlockStyle}>
            <span className={styles.underlineStyle}></span>
            <p className={styles.signupPromptStyle}>Or Sign Up Using</p>
            <span className={styles.underlineStyle}></span>
          </div>
          <FcGoogle
            className={styles.googleIconStyle}
            onClick={handleGoogleNavigation}
          />
        </div>
      </section>
    );
  };
  const handleGoogleNavigation = async () => {
    try {
      axios.defaults.withCredentials = true;
      const googleUrl = `${process.env.REACT_APP_MEDIA_URL}/api/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_MAIN_APP}`;
      const res = await axios.get(googleUrl);

      if (res.status === 200) {
        // Redirect to Google OAuth URL
        window.location.href = res.data.authorization_url;
      } else {
        console.error("Error fetching Google OAuth URL");
      }
    } catch (error) {
      console.error("Error during Google OAuth navigation:", error);
    }
  };

  const handleGoogleStateCode = useCallback(async (state, code) => {
    axios.defaults.withCredentials = true;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/o/google-oauth2/?state=${state}&code=${code}`,
        { withCredentials: true }
      );

      if (response.status === 201) {
        const { access, refresh, user } = response.data;

        // Store tokens in localStorage
        localStorage.setItem("isGoogleLogin", "true");
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        localStorage.setItem("user", JSON.stringify(user)); // Stringify object
        navigate("/dashboard");

        setGoogleUserData({
          accessToken: access,
          refreshToken: refresh,
          user: user,
        });
      } else {
        console.error("Error in Google OAuth response:", response);
      }
    } catch (error) {
      console.error("Error in Google OAuth handling:", error);
    }
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    const code = urlParams.get("code");
    if (state && code) {
      handleGoogleStateCode(state, code);
    }
  }, [window.location.search]);
  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/jwt/verify/`, { token })
        .then((res) => {
          if (res.status === 200) {
            navigate("/dashboard");
          } else {
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Token verification failed:", error);
          navigate("/login");
        });
    }
  }, [navigate]);

  const postAccessTokenToAPI = async () => {
    try {
      const { accessToken, user } = googleUserData;
      if (!accessToken) {
        console.error("No access token available");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_MEDIA_URL}/api/users/me/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Check if the user is inactive
      if (
        response.data.details &&
        response.data.details.code === "user_inactive"
      ) {
        await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/users/resend_activation/`,
          { email: user }
        );
      }
    } catch (error) {
      // Handle 401 error specifically
      if (error.response && error.response.status === 401) {
        try {
          await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/users/resend_activation/`,
            { email: googleUserData.user }
          );
        } catch (err) {
          console.error("Error sending activation request:", err.response);
        }
      }
    }
  };

  useEffect(() => {
    if (googleUserData.accessToken) {
      postAccessTokenToAPI();
    }
  }, [googleUserData.accessToken]);

  return (
    <div className={styles.loginBackgroungImg}>
      <main className={styles.mainContainerStyle}>
        {renderBottomSection()}
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <span
                className={styles.closeButton}
                onClick={() => setIsModalOpen(false)}
              >
                &times;
              </span>
              <p className={styles.forgotHeadingStyle}>Forgot Password?</p>
              <p className={styles.forgotSubTextStyle}>
                Enter your email address and we will send you a link to reset
                your password.
              </p>
              <input
                type="email"
                placeholder="Enter your email"
                value={emailForReset}
                onChange={(e) => setEmailForReset(e.target.value)}
                className={styles.modalInput}
              />
              <button
                onClick={handleResetPassword}
                className={styles.modalButton}
              >
                {isResetLoading ? "Sending..." : "Send Reset Link"}
              </button>
              {isResetLoading && (
                <CircularProgress className={styles.forgotLoader} />
              )}
              {resetMessage && (
                <p className={styles.resetMessage}>{resetMessage}</p>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Login;
