import { useState, useEffect } from "react";
import axiosInstance from "utils/axios";
import { useAppDataContext } from "hooks/useappdatacontext";
import { useMediaQuery } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal } from "@mui/material";
import styles from "./styles.module.css";

const Settings = () => {
  const { getAllChannelsApi, allChannelsData, setAllChannelsData, deleteChannel } = useAppDataContext();
  const [selectedOption, setSelectedOption] = useState("allChannels");
  const [paymentData, setPaymentData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const isMobile = useMediaQuery("(max-width:800px)");

  useEffect(() => {
    fetchAllChannels();
    fetchPayments();
  }, []);

  const fetchAllChannels = async () => {
    await getAllChannelsApi();
  };

  const fetchPayments = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_MEDIA_URL}/api/pay/get_all_pay/`);
      if (response.data) {
        setPaymentData(response.data);
      }
    } catch (error) {
      console.error("Error fetching payments", error);
    }
  };

  const handleDelete = (channelId) => {
    setShowDeleteConfirm(true);
    setSelectedChannel(channelId);
  };

  const confirmDelete = async () => {
    await deleteChannel(selectedChannel);
    setAllChannelsData(allChannelsData?.filter((channel) => channel.id !== selectedChannel));
    setShowDeleteConfirm(false);
  };

  const handleView = (channel) => {
    setSelectedChannel(channel);
    setShowModal(true);
  };

  return (
    <div className={styles.appContainer}>
      <h1 className={styles.title} style={{ marginTop: "8vh" }}>Channel & Payment Management</h1>
      <div className={styles.radioButtons}>
        <button className={`${styles.radioButton} ${selectedOption === "allChannels" ? styles.selected : ""}`} onClick={() => setSelectedOption("allChannels")}>
          All Channels
        </button>
        <button className={`${styles.radioButton} ${selectedOption === "paymentStatus" ? styles.selected : ""}`} onClick={() => setSelectedOption("paymentStatus")}>
          Payment Status
        </button>
      </div>

      {selectedOption === "allChannels" && (
        <>
          {allChannelsData.length > 0 ? (
            <TableContainer component={Paper} className={styles.channelTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    {!isMobile && <TableCell className={styles.tableHeadingStyle}>ID</TableCell>}
                    <TableCell className={styles.tableHeadingStyle}>Name</TableCell>
                    <TableCell className={styles.tableHeadingStyle}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allChannelsData?.map((channel) => (
                    <TableRow key={channel.id}>
                      {!isMobile && <TableCell className={styles.tableDataStyle}>{channel.id}</TableCell>}
                      <TableCell className={styles.tableDataStyle}>{channel.name.length > 15 ? `${channel.name.slice(0, 15)}...` : channel.name}</TableCell>
                      <TableCell className={styles.tableDataStyle} style={{ display: "flex", gap: "10px" }}>
                        <Button className={styles.viewBtn} onClick={() => handleView(channel)} variant="contained" color="primary">View</Button>
                        <Button className={styles.deleteBtn} onClick={() => handleDelete(channel.id)} variant="contained" color="secondary">Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className={styles.noDataText}><p>No channels found</p></div>
          )}
        </>
      )}

      {selectedOption === "paymentStatus" && (
        <TableContainer component={Paper} className={styles.paymentStatusTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.paymentStatusTableHeading}>Order ID</TableCell>
                <TableCell className={styles.paymentStatusTableHeading}>Amount</TableCell>
                <TableCell className={styles.paymentStatusTableHeading}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData.map((payment) => (
                <TableRow key={payment.order_id}>
                  <TableCell className={styles.paymentStatusTableData}>{payment.order_id}</TableCell>
                  <TableCell className={styles.paymentStatusTableData}>₹{payment.amount}</TableCell>
                  <TableCell className={styles.paymentStatusTableData}>
                    <span className={payment.status === "completed" ? styles.statusCompletedStyle : payment.status === "failed" ? styles.statusFailedStyle : styles.statusCancelledStyle}>
                      {payment.status}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal for Channel Details */}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className={styles.modalContent}>
          <h2>Channel Details</h2>
          <p><strong>Name:</strong> {selectedChannel?.name}</p>
          <p><strong>ID:</strong> {selectedChannel?.id}</p>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
        <div className={styles.deleteContent}>
          <h2 className={styles.deletePromptStyle}>Are you sure you want to delete this channel?</h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button className={styles.deleteBtnStyle} onClick={confirmDelete}>Yes</Button>
            <Button onClick={() => setShowDeleteConfirm(false)} variant="outlined" color="secondary">No</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
