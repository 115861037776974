import { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormControl,
  FormLabel,
} from "@mui/material";
import axiosInstance from "utils/axios";
import "./index.css";
import styles from "./dropDownCheck.module.css";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const DropDownCheck = ({ newEventData, setNewEventData }) => {
  // const { newEventData, setNewEventData } = useAppDataContext();

  const [isChecked, setIsChecked] = useState(false);
  const [selectedPasswordOption, setSelectedPasswordoption] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const [fetchedPassword, setFetchedPassword] = useState("");
  const [minimumPassword, setMinimumPassword] = useState("");

  const [passwordVisibilty, setPasswordVisibility] = useState(true);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    setNewEventData((prev) => ({
      ...prev,
      is_secure: `${newCheckedState}`,
    }));

    resetForm();
  };

  const handleSelectedOptionChange = async (event) => {
    const value = event.target.value;
    setSelectedPasswordoption(value);
    resetForm();

    if (value === "generatePassword") {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_MEDIA_URL}/api/events/create_Secret/`
        );
        setFetchedPassword(response.data.message);
        setNewEventData((prev) => ({
          ...prev,
          password: response.data.message,
        }));
      } catch (error) {
        setApiError("Failed to fetch password. Please try again.");
      }
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setNewEventData((prev) => ({
      ...prev,
      password: value,
    }));
    validatePassword(value);
  };

  const validatePassword = (value) => {
    if (value.length > 0 && value.length <= 8) {
      setMinimumPassword("It's a Valid Password");
    } else if (value.length === 0) {
      setMinimumPassword("");
    } else {
      setPasswordError("Password must be at least 8 characters long.");
    }
  };

  const resetForm = () => {
    setNewEventData((prev) => ({
      ...prev,
      password: "",
    }));
    setPasswordError("");
    setApiError("");
    setApiSuccess("");
    setFetchedPassword("");
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
        }
        label="Protect with password"
        className={styles.labelTextStyle}
      />
      {isChecked && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl
            component="fieldset"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <FormLabel component="div">Select an option</FormLabel>
            <RadioGroup
              value={selectedPasswordOption}
              onChange={handleSelectedOptionChange}
              sx={{
                height: "40px",
                display: "flex",
              }}
            >
              <FormControlLabel
                value="generatePassword"
                control={<Radio />}
                label="Generate password"
              />
              <FormControlLabel
                value="customPassword"
                control={<Radio />}
                label="Set custom password"
              />
            </RadioGroup>
          </FormControl>

          {selectedPasswordOption === "generatePassword" && (
            <Box>
              <TextField
                type="text"
                placeholder="Auto-generated password"
                value={fetchedPassword}
                fullWidth
                InputProps={{ readOnly: true }}
              />
              {apiError && <Typography color="error">{apiError}</Typography>}
            </Box>
          )}

          {selectedPasswordOption === "customPassword" && (
            <Box>
              <div
                style={{
                  position: "relative",
                }}
              >
                <TextField
                  type={!passwordVisibilty ? "text" : "password"}
                  placeholder="Enter password"
                  value={newEventData?.password}
                  onChange={handlePasswordChange}
                  fullWidth
                  error={Boolean(passwordError)}
                  helperText={passwordError}
                  inputProps={{ maxLength: 8, minLength: 1 }}
                />
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                    position: "absolute",
                    right: 10,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={() => {
                    setPasswordVisibility(!passwordVisibilty);
                  }}
                >
                  {passwordVisibilty ? (
                    <MdVisibility size={25} />
                  ) : (
                    <MdVisibilityOff size={25} />
                  )}
                </button>
              </div>

              {minimumPassword && (
                <p style={{ color: "green", marginTop: "2px" }}>
                  {minimumPassword}
                </p>
              )}
              {apiError && <Typography color="error">{apiError}</Typography>}
              {apiSuccess && (
                <Typography color="success.main">{apiSuccess}</Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DropDownCheck;
