import { useState, useEffect } from "react";
import { useAppDataContext } from "hooks/useappdatacontext";
import prase from "html-react-parser";
import DOMPurify from "dompurify";

const NavBar = ({ fnStyle, title }) => {
  // States
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Contexts
  const { singleEventData } = useAppDataContext();

  const cleanEventTitleHtml = DOMPurify.sanitize(singleEventData?.title);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Update `isMobile` on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        padding: "20px",
        fontFamily: "Roboto",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        fontSize: "1.5rem",
        width: "100%",
        position: "relative",
      }}
    >
      <a href="/">
        <img
          src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
          style={{
            height: "40px",
            width: "150px",
            marginRight: isMobile ? "0" : "20px",
            marginBottom: isMobile ? "10px" : "0",
          }}
          alt="nav-logo"
        />
      </a>
      <p
        style={{
          position: isMobile ? "static" : "absolute",
          top: isMobile ? "0" : "29px",
          left: isMobile ? "0" : "46%",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        {cleanEventTitleHtml !== ""
          ? prase(title ? title : singleEventData?.title)
          : title
          ? title
          : singleEventData?.tile}
      </p>
    </nav>
  );
};

export default NavBar;
