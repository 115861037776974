import { Box, Card, CardContent, Typography } from "@mui/material";
import styles from "./muiCard.module.css";

const MuiCard = ({ value, title, width, height, backgroundColor, color }) => {
  return (
    <Box width={width}>
      <Card
        sx={{ height: height, backgroundColor: backgroundColor, color: color }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className={styles.titleStyle}
          >
            {value}
          </Typography>
          <Typography variant="body2" className={styles.subTitleStyle}>
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MuiCard;
