import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import Edit from "@mui/icons-material/Edit";
import PermIdentityTwoToneIcon from "@mui/icons-material/PermIdentityTwoTone";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { routeNames } from "constants";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useauthcontext";
import styles from "./userInfoTooltip.module.css";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";

const UserInfoTooltip = () => {
  // routing:
  const navigate = useNavigate();

  // contexts:
  const { userData, setUserData, editUserInfo, logOut } = useAuthContext();

  // states:
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUserEdit = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(()=>{
    if (!userData){
      getUSerData()
    }
  },[userData])
  const getUSerData=async ()=>{
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(
      `${process.env.REACT_APP_MEDIA_URL}/api/users/me/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.data){
      setUserData(response.data);
    }
  }
  const handleSave = () => {
    setIsLoading(true);
    editUserInfo(userData);
    setIsLoading(false);
    handleCloseModal();
  };
  return (
    <div>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, marginLeft: "0px" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, backgroundColor: "#01579b" }}>
            {userData?.first_name?.charAt(0)?.toUpperCase() || "L"}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleClose}
          className={styles.userActionsOptionStyles}
        >
          <ListItemIcon>
            <PermIdentityTwoToneIcon fontSize="small" />
          </ListItemIcon>
          {`${userData?.first_name} ${userData?.last_name}`}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className={styles.userActionsOptionStyles}
        >
          <ListItemIcon>
            <PhoneTwoToneIcon fontSize="small" />
          </ListItemIcon>
          {userData?.phone_number}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className={styles.userActionsOptionStyles}
        >
          <ListItemIcon>
            <MailTwoToneIcon fontSize="small" />
          </ListItemIcon>
          {userData?.email}
        </MenuItem>
        {/* {userData?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
           className={styles.userActionsOptionStyles}
          >
            {item}
          </MenuItem>
        ))} */}
        <Divider />
        <MenuItem onClick={handleOpenModal}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            logOut();
            navigate(routeNames?.login);
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      {/* Modal for editing profile */}
      <Dialog
        open={openModal}
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            display: "flex",
            flexDirection: "column",
            width: "700px",
            maxWidth: "none",
            padding: "0 20px 20px 0",
          },
        }}
        onClose={handleCloseModal}
      >
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
            value={userData?.first_name}
            onChange={handleUserEdit}
          />
          <TextField
            margin="dense"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            value={userData?.last_name}
            onChange={handleUserEdit}
          />
          <TextField
            margin="dense"
            name="phone_number"
            label="Phone Number"
            type="text"
            fullWidth
            variant="outlined"
            value={userData?.phone_number}
            onChange={handleUserEdit}
          />
          {/* Add more fields as necessary */}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseModal}
            className={styles.closeBtn}
          >
            cancel
          </Button>
          <Button onClick={handleSave} variant="contained">
            {isLoading ? (
              <>
                Updating...
                <CircularProgress size={24} className={styles.loader} />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserInfoTooltip;
