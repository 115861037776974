export const userLogin = "/jwt/create/";

export const userSignUp = "/users/";

export const userData = "/users/me/";

export const eventsData = "/events/create_event/";

export const updateEventData = "/events/update_event/";

export const deleteEventData = "/events/delete_event/{id}/";

export const getAllEvents = "/events/get_all_events/";

export const getEvent = "/events/get_event/";

export const getRegions = "/stream/get_regions/";

export const createChannel = "/stream/create_channel/";

export const getCredits = "/events/get_user_credits/";

export const allChannelsData = "/stream/get_channels/";

export const deleteChannel = "/stream/delete_channel/{id}/";

export const pricingData = "/pay/get_pricing/";

export const feedbackData = "/contact/";

export const customHls = "/stream/get_rec_url/{id}/";
