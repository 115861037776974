import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const services = [
  {
    eventTitle: "Birthday Parties",
    eventDescription:
      "Unforgettable parties with streaming and photography for memories to last a lifetime.",
    eventImage:
      "https://res.cloudinary.com/dmhx6frzp/image/upload/v1727954078/pexels-ivan-samkov-9627802_bbm7gy.jpg",
  },
  {
    eventTitle: "Weddings Coverages",
    eventDescription:
      "Magical weddings with decor, streaming, and photos capturing every cherished moment.",
    eventImage:
      "https://res.cloudinary.com/dmhx6frzp/image/upload/v1727954138/pexels-photography-maghradze-ph-1659410-27204455_qxasom.jpg",
  },
  {
    eventTitle: "Cultural Events",
    eventDescription:
      "Celebrate culture with traditional setups, streaming, and vibrant event photography.",
    eventImage:
      "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728039441/pexels-azim-khan-ronnie-148764-28292124_ddsgjf.jpg",
  },
  {
    eventTitle: "Holiday Parties",
    eventDescription:
      "Festive holiday parties with themed decor, entertainment, and memorable experiences.",
    eventImage:
      "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728039492/pexels-fauxels-3184183_maugxs.jpg",
  },
  {
    eventTitle: "Family Reunions",
    eventDescription:
      "Memorable reunions with live streaming, photography, and personalized experiences.",
    eventImage:
      "https://res.cloudinary.com/dmhx6frzp/image/upload/v1727954572/pexels-george-chambers-413080139-24355294_ly8fuh.jpg",
  },
  {
    eventTitle: "Concerts and Music Festivals",
    eventDescription:
      "Dynamic festivals with streaming and photos to capture every exciting moment.",
    eventImage:
      "https://res.cloudinary.com/dmhx6frzp/image/upload/v1727954650/pexels-nandhukumar-1613240_nlut0c.jpg",
  },
];

const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <section id="services" className="custom-services-gradient-section">
      <div className="custom-services-container">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="text-services-section-center"
        >
          <motion.h2
            variants={itemVariants}
            className="service-section-heading"
          >
            Our Services
          </motion.h2>
          <motion.p variants={itemVariants} className="service-section-text">
            Choose the perfect plan for your streaming needs
          </motion.p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="custom-services-grid"
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="service-card"
            >
              <img
                src={service.eventImage}
                alt={service.eventTitle}
                className="service-card-img"
              />
              <h3 className="service-card-title">{service.eventTitle}</h3>
              <p className="service-card-description">
                {service.eventDescription}
              </p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
