import React, { useEffect, useState } from "react";
import axios from "axios";
import { routeNames } from "constants";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const Activation = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const [activationStatus, setActivationStatus] = useState("");
  const [activationError, setActivationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleActivation = async () => {
    const baseUrl = `${process.env.REACT_APP_MEDIA_URL}/api/users/activation/`;
    setIsLoading(true);

    try {
      const response = await axios.post(
        baseUrl,
        {
          uid: uid,
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 204) {
        setActivationStatus("Activation successful");
        // const googleLogin = localStorage.getItem("isGoogleLogin") === "true";
        setIsLoading(false);
        // if (googleLogin) {
        //   navigate(routeNames.dashBoard);
        // } else {
        //   navigate(routeNames.login);
        // }
        navigate(routeNames.login);
      }
    } catch (error) {
      setIsLoading(false);
      setActivationError("Activation failed");
      console.error("Activation Error: ", error);
    }
  };

  useEffect(() => {
    handleActivation();
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Activation Page</h1>
      {isLoading && (
        <p className={styles.loading}>
          Activating your account, please wait...
        </p>
      )}
      {activationStatus && <p className={styles.message}>{activationStatus}</p>}
      {activationError && (
        <div className={styles.error}>
          <h3>{`Activation Error: ${activationError}`}</h3>
        </div>
      )}
    </div>
  );
};

export default Activation;
