

import axios from "axios";
import { jwtDecode } from "./jwt";

// Token expired function
export const tokenExpired = (exp) => {
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  if (timeLeft <= 0) {
    localStorage.removeItem("accessToken");
    window.location.href = "/";
  } else {
    setTimeout(() => {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    }, timeLeft);
  }
};

// Set Axios session
export const setAxiosSession = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const { exp } = jwtDecode(token);
    tokenExpired(exp);
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};



// import axios from "utils/axios";
// import { jwtDecode } from "./jwt";

// // token expired

// export const tokenExpired = (exp) => {
//   let expiredTimer;
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   clearTimeout(expiredTimer);

//   expiredTimer = setTimeout(() => {
//     alert("Token expired");
//     localStorage.removeItem("accessToken");
//     window.location.href = "/";
//   }, timeLeft);
// };

// // setAxiosSession-

// export const setAxiosSession = (token) => {
//   if (token) {
//     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//     // this function will handle when token is expired
//     const { exp } = jwtDecode(token); // ~ 3 days by minimal server
//     tokenExpired(exp);
//   } else {
//     localStorage.removeItem("token");
//     delete axios.defaults.headers.common.Authorization;
//   }
// };
