import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./global.css";

const VideoPlayer = ({ options }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const initializePlayer = () => {
      if (videoRef.current && !playerRef.current) {
        const videoNode = videoRef.current;

        // Initialize Video.js player
        playerRef.current = videojs(videoNode, options, () => {
          console.log("Player is ready");
        });

        // Event listener for error handling
        playerRef.current.on("error", (e) => {
          console.error("Video.js Error:", e);
        });
      }
    };

    const timeoutId = setTimeout(() => {
      initializePlayer();
    }, 100); // Delay to ensure video element is in DOM

    return () => {
      clearTimeout(timeoutId);
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [options]);

  return (
    <div
      data-vjs-player
      style={{ width: "500px !important", height: "400px !important" }}
    >
      {/* className={styles.hlsplayerBlockStyle} */}
      <video ref={videoRef} className="video-js" />
    </div>
  );
};

export default VideoPlayer;
