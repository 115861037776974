import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { useLocation, useParams } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery";
import HLSPlayer from "components/hlsPlayer";
import { CoupleFrame } from "../../resources/images";
import { useAppDataContext } from "hooks/useappdatacontext";
import styles from "./weddingTemplate.module.css";

import LoadingView from "./LoadingView";
import DOMPurify from "dompurify";
import { BsCalendar2DateFill } from "react-icons/bs";
import { MdAccessTimeFilled, MdPhoneCallback } from "react-icons/md";

const WeddingTemplate = () => {
  const location = useLocation();
  const [id, setID] = useState(null);
  const [offlineVideoDimensions, setOfflineVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const vidRef = useRef(null);

  // const id = location?.state?.userId;
  useEffect(() => {
    const str = location.pathname;
    // Updated regex to capture the last segment of the path
    const match = str.match(/\/([a-zA-Z0-9-]+)$/);

    if (match) {
      const newId = match[1];
      const dec = atob(newId);
      setID(dec);
    }
  }, [location.pathname]);
  // states

  const [showTemplate, setShowTemplate] = useState(true);
  const [newdas, setnewdas] = useState([]);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // contexts
  const { getEvent, singleEventData, showLoader, setShowLoader } =
    useAppDataContext();



  const validateTitle = DOMPurify.sanitize(singleEventData?.title);


  const path = useParams();

  useEffect(() => {
    const { title, id } = path;
    const getSingleEvent = async () => {
      setShowLoader(true);
      const newdas = await getEvent(title, id);
      if (newdas) {
        setnewdas(newdas);
      }
      setShowLoader(false);
      if (newdas.is_secure === true) {
        setShowTemplate(false);
      }
    };
    if (id) {
      getSingleEvent();
    }
  }, [id]);

  const handleSecureValidation = () => {
    if (password === newdas.password) {
      setShowTemplate(true);
    } else {
      setError("Password Invalid");
    }
  };

  const extractYouTubeVideoId = () => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = singleEventData?.live_vidlink?.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
  };

  const extractYouTubeLiveId = () => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = singleEventData?.live_vidlink?.match(regExp);
    return match ? match[1] : null;
  };

  const extractHlsUrl = () => {
    const regExp = /https?:\/\/[^\s]*\.m3u8/;
    const match = singleEventData?.live_vidlink?.match(regExp);
    return match ? match[0] : null;
  };


  const renderSpecificBannerView = () => {
    // console.log("singleEventData.template", singleEventData.template);
    switch (singleEventData.template) {
      case "Birthday":
      case "Saree Ceremony":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728558003/5395531_fkgvby.jpg";
      case "Marriage":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728635524/japanese-paper-textures_vhxore.jpg";
      case "Cultural Event":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728636985/frame-with-flowers-it-that-says-golden_nkloxz.jpg";
      case "Family Reunion":
      case "Party":
        return "";
      default:
        return CoupleFrame;
    }
  };

  const renderBannerImage = () => {
    // const randomNumberForBanerSelection = Math.round(Math.random());
    // console.log(randomNumberForBanerSelection);
    return (
      <>
        {singleEventData && singleEventData.banner ? (
          <div id="home" className={styles.banner_block}>
            <img
              src={singleEventData.banner}
              alt="Banner_image"
              className={styles.banner_image}
            />
          </div>
        ) : (
          <div className={styles.frameContainer}>
            <img
              src={renderSpecificBannerView()}
              alt="Frame"
              className={styles.frameImage}
            />
            <p className={styles.frameContent}>
              {validateTitle !== ""
                ? parse(singleEventData?.title)
                : singleEventData?.title}
            </p>
          </div>
        )}
      </>

      // (
      //   <div className={styles.frameContainer}>
      //     {/* <img src={CoupleFrame} alt="Frame" className={styles.frameImage} /> */}
      //     <img
      //       src="https://res.cloudinary.com/dmhx6frzp/image/upload/v1728558003/5395531_fkgvby.jpg"
      //       alt="Frame"
      //       className={styles.frameImage}
      //     />
      //     {/* <p className={styles.frameContent}>
      //     Danial <br />& <br />
      //     Floes
      //     </p> */}
      //     <p className={styles.frameContent}>{singleEventData?.title}</p>
      //   </div>
      // )
      //   }
      // </>
    );
  };

  const renderNavigation = () => {
    return <NavBar />;
  };

  // extracts day, month, and year

  const videoRef = useRef(null);
  const [resolution, setResolution] = useState({ width: 0, height: 0 });

  const renderInvitationSection = () => {
    //tag validatore
    const cleanHtml = (html) => {
      const isTrue = DOMPurify.sanitize(html);
      if (isTrue) {
        return parse(html);
      } else {
        return html;
      }
    };

    //video resolution finder
    const handleLoadedMetadata = () => {
      const video = videoRef.current;
      if (video) {
        const { videoWidth, videoHeight } = video;
        setResolution({ width: videoWidth, height: videoHeight });
      }
    };
    const isPortrait = resolution.height >= resolution.width;

    return (
      <>
        {singleEventData && singleEventData.invitation_card ? (
          <div id="invitation">
            <div className={styles.invitation_container}>
              <p className={styles.invitationHeading}>Invitation</p>
              <div className={styles.invitation_media_block}>
                <div className={styles.invitationImageBlock}>
                  {singleEventData?.invitation_card && (
                    <img
                      src={singleEventData?.invitation_card}
                      className={styles.invitation_card_img}
                      alt="Invitation card"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.invitationFrame}>
            <div>
              <h1
                style={{
                  fontFamily: "math",
                  fontStyle: "oblique",
                  textDecoration: "underline",
                }}
              >
                Venue:
              </h1>

              {/* <span>
                  <FaLocationDot className="location-icon" />
                </span> */}
              <div
                className={styles.marginBottom}
                style={{
                  display: "flex",

                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px 0px 20px 0px",
                }}
              >
                {cleanHtml(singleEventData?.venue)}
              </div>

              <div
                className={styles.timeDateWedding}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BsCalendar2DateFill className="location-icon" />
                  <div>{singleEventData?.venue_date}</div>
                </div>

                <p style={{ display: "flex", alignItems: "center" }}>
                  <MdAccessTimeFilled className="location-icon" />{" "}
                  <span>
                    {new Date(
                      `1970-01-01T${singleEventData?.venue_time}`
                    ).toLocaleTimeString([], {
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </span>
                </p>
              </div>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.marginBottom}
              >
                <MdPhoneCallback className="location-icon" />
                {singleEventData?.contact}
              </p>
              <div className={styles.marginBottom}>
                Invited By {cleanHtml(singleEventData?.template_desc)}
              </div>
            </div>
          </div>
        )}
        {singleEventData && singleEventData?.invitation_video && (
          <>
            {isPortrait ? (
              <div
                style={{
                  backgroundImage:
                    "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728547161/9585_2_gozke5.jpg')",
                }}
                className={styles.videoContainerForHigherHight}
              >
                <video
                  style={{ transform: "rotate(-90deg)" }}
                  ref={videoRef}
                  onLoadedMetadata={handleLoadedMetadata}
                  controls
                  className={styles.invitationVideoBlockForHigherHeight}
                >
                  <source
                    src={singleEventData?.invitation_video}
                    type="video/mp4"
                  />
                </video>
              </div>
            ) : (
              <div
                style={{
                  backgroundImage:
                    "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728547161/9585_2_gozke5.jpg')",
                }}
                className={styles.videoContainer}
              >
                <video
                  ref={videoRef}
                  onLoadedMetadata={handleLoadedMetadata}
                  controls
                  className={styles.invitationVideoBlock}
                >
                  <source
                    src={singleEventData?.invitation_video}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const renderLiveVideoSection = () => {
    const handleLoadedMetadataOfflineVideo = () => {
      if (vidRef.current) {
        setOfflineVideoDimensions({
          width: vidRef.current.videoWidth,
          height: vidRef.current.videoHeight,
        });
      }
    };

    const isLandscape =
      offlineVideoDimensions.width > offlineVideoDimensions.height;

    // console.log(isLandscape);

    const videoId = extractYouTubeVideoId();
    const liveId = extractYouTubeLiveId();
    const hlsUrl = extractHlsUrl();

    if (videoId) {
      return (
        <div
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
          }}
          className={styles.videoContainer}
        >
          <div className={styles.youtube_recorded_block}>
            <iframe
              className={styles.youtube_recorded}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </div>
      );
    } else if (liveId) {
      return (
        <div
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
          }}
          className={styles.videoContainer}
        >
          <div className={styles.youtube_live_block}>
            <iframe
              title="Live"
              className={styles.youtube_live}
              src={`https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      );
    } else if (hlsUrl) {
      return (
        <div
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
          }}
          className={styles.videoContainer}
        >
          <HLSPlayer />
        </div>
      );
    } else if (
      singleEventData?.offline_video != null &&
      singleEventData?.offline_video !== ""
    ) {
      return (
        <div
          style={{
            backgroundImage: `url(
                 "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg")`,
          }}
          className={`${
            isLandscape
              ? styles.videoContainer
              : styles.videoContainerForHigherHight
          }`}
        >
          <video
            style={{
              transform: !isLandscape && "rotate(-90deg)",
              width: isLandscape ? "97%" : "50%",
            }}
            ref={vidRef}
            controls
            onLoadedMetadata={handleLoadedMetadataOfflineVideo}
          >
            <source src={singleEventData?.offline_video} type="video/mp4" />
          </video>
        </div>
      );
    }
  };

  const renderGallerySection = () => {
    return (
      <div id="gallery" className={styles.galleryBlock}>
        {singleEventData &&
          Array.isArray(singleEventData.images) &&
          singleEventData.images.length > 0 && (
            <>
              <p className={styles.galleryHeading}>Our Wedding Gallery</p>
              <div style={{ width: "100%" }}>
                <FancyBoxGallery />
              </div>
            </>
          )}
      </div>
    );
  };

  const renderFooterSection = () => {
    return (
      <>
        <div>
          <p className={styles.footerHeading}>Forever our love, Thank you</p>
          <p>Copyright 2024, Made with love by Infinitum Tech</p>
        </div>
      </>
    );
  };

  return (
    <>
      {showTemplate ? (
        <>
          {!showLoader ? (
            <div
              style={{ minWidth: "300px" }}
              // className={`${styles.templateTransitionGlobal} ${
              //   componentsVisible ? styles.fadeIn : styles.fadeOut
              // }`}
            >
              {renderNavigation()}
              <div className={styles.templateGlobal}>
                {renderBannerImage()}
                {/* {renderVenueSection()} */}
                {renderInvitationSection()}
                {renderLiveVideoSection()}
                {renderGallerySection()}
                {renderFooterSection()}
              </div>
            </div>
          ) : (
            <LoadingView />
          )}
        </>
      ) : (
        <>
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <h1 className={styles.modalTitle}>
                This event is password protected
              </h1>
              <h2 className={styles.modalSubTitle}>Please Enter Password</h2>
              <input
                type="text"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.inputField}
              />
              {error && <p className={styles.errorMessage}>{error}</p>}
              <button
                onClick={handleSecureValidation}
                className={styles.submitBtn}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WeddingTemplate;
