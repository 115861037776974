import React from "react";

const UploadProgressPopup = ({ progress, isUploading }) => {
  if (!isUploading) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2 style={styles.title}>Uploading File</h2>
        <p style={styles.message}>Please do not refresh or close the window while the file is uploading.</p>
        <div style={styles.progressBarContainer}>
          <div style={{ ...styles.progressBar, width: `${progress}%` }} />
        </div>
        <p style={styles.progressText}>{progress}% complete</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "10px",
    textAlign: "center",
    maxWidth: "400px",
    width: "90%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    animation: "fadeIn 0.3s ease",
  },
  title: {
    fontSize: "1.5em",
    color: "#333",
    marginBottom: "10px",
  },
  message: {
    fontSize: "1em",
    color: "#555",
    marginBottom: "20px",
  },
  progressBarContainer: {
    width: "100%",
    height: "20px",
    backgroundColor: "#e0e0e0",
    borderRadius: "10px",
    overflow: "hidden",
    margin: "15px 0",
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.2)",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#4caf50",
    transition: "width 0.2s ease-in-out",
  },
  progressText: {
    fontSize: "1.1em",
    color: "#333",
    fontWeight: "bold",
    marginTop: "10px",
  },
};

export default UploadProgressPopup;
