import React from "react";
import { menuData, routeNames } from "constants";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./sideBarMenu.module.css";

const SidebarMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (name) => {
    switch (name) {
      case "Dashboard":
        navigate(routeNames.dashBoard);
        break;

      case "Create event":
        navigate(routeNames.event);
        break;

      case "Pricing":
        navigate(routeNames.payments);
        break;

      case "Settings":
        navigate(routeNames.settings);
        break;

      default:
        break;
    }
  };

  const renderTopSection = () => {
    // return <section className={styles.topSectionStyle}>Infinitum Tech</section>;
  };

  const renderBottomSection = () => {
    return (
      <div style={{ display: "flex", gap: "16px" }}>
        <section className={styles.bottomSectionStytle}>
          {menuData?.map((item, index) => {
            const isActiveMenuItem = location.pathname.includes(item?.route);
            return (
              <div
                className={
                  isActiveMenuItem
                    ? styles.activeMenuItemStyle
                    : styles.menuItemStyle
                }
                key={index}
                onClick={() => handleNavigation(item?.name)}
              >
                <div className={styles.menuIconStyle}>{item?.icon}</div>
                <p className={styles.menuItemTextStyle}>{item?.name}</p>
              </div>
            );
          })}
        </section>
      </div>
    );
  };

  return (
    <main className={styles.sidebarContainerStyle}>
      {renderTopSection()}
      {renderBottomSection()}
    </main>
  );
};

export default SidebarMenu;
