import React, { useState } from "react";
import { menuData, routeNames } from "constants";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./appsideBarMenu.module.css";

const AppSidebarMenu = () => {
  const [isOpen, setIsOpen] = useState(false); // State to handle the drawer toggle
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (name) => {
    switch (name) {
      case "Dashboard":
        navigate(routeNames.dashBoard);
        break;
      case "Create event":
        navigate(routeNames.event);
        break;
      case "Pricing":
        navigate(routeNames.payments);
        break;
      case "Settings":
        navigate(routeNames.settings);
        break;
      default:
        break;
    }
    setIsOpen(false); // Close the drawer when any link is clicked
  };

  const renderMenuItems = () => {
    return (
      <div className={styles.menuItemsContainer}>
        {menuData?.map((item, index) => {
          const isActiveMenuItem = location.pathname.includes(item?.route);
          return (
            <div
              className={
                isActiveMenuItem
                  ? styles.activeMenuItemStyle
                  : styles.menuItemStyle
              }
              key={index}
              onClick={() => handleNavigation(item?.name)}
            >
              <div className={styles.menuIconStyle}>{item?.icon}</div>
              <p className={styles.menuItemTextStyle}>{item?.name}</p>
            </div>
          );
        })}
        
      </div>
    );
  };

  return (
    <main className={styles.sidebarContainerStyle}>
      {/* Menu Button for small screens */}
      <button 
        className={styles.menuButton} 
        onClick={() => setIsOpen(!isOpen)}
      >
        ☰
      </button>

      {/* Sidebar Drawer */}
      <div className={`${styles.drawer} ${isOpen ? styles.drawerOpen : ''}`}>
        {renderMenuItems()}
      </div>
    </main>
  );
};

export default AppSidebarMenu;
