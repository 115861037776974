import axios from "utils/axios";
import * as endpoints from "networking/endpints";

export const userLoginApi = async (data) =>
  Promise.resolve(axios.post(endpoints.userLogin, data));

export const userSignUpApi = async (data) =>
  Promise.resolve(axios.post(endpoints.userSignUp, data));

export const getUserDataApi = async () =>
  Promise.resolve(axios.get(endpoints.userData));

export const editUserInfoApi = async (updatedData) =>
  Promise.resolve(axios.put(endpoints.userData, updatedData));

export const eventDataApi = async (data) => {
  return Promise.resolve(axios.post(endpoints.eventsData, data));
};

export const updateEventDataApi = async ( data) => {
  const url = endpoints.updateEventData;
  return axios.patch(url, data);
};

export const deleteEventDataApi = async (id) => {
  const url = endpoints.deleteEventData.replace("{id}", id);
  return axios.delete(url);
};

export const getAllEventsApi = async () =>
  Promise.resolve(axios.get(endpoints.getAllEvents));

export const getEventApi = async () =>
  Promise.resolve(axios.get(endpoints.getEvent()));

export const allRegionsApi = async () =>
  Promise.resolve(axios.get(endpoints.getRegions));

export const createChannelApi = async (payload) =>
  Promise.resolve(axios.post(endpoints.createChannel, payload));

export const creditsApi = async () =>
  Promise.resolve(axios.get(endpoints.getCredits));

export const allChannelsApi = async () =>
  Promise.resolve(axios.get(endpoints.allChannelsData));

export const deleteChannelApi = async (id) =>
  Promise.resolve(axios.delete(endpoints.deleteChannel.replace("{id}", id)));

export const pricingDataApi = async () =>
  Promise.resolve(axios.get(endpoints.pricingData));

export const updateCustomHls = async (id) =>
  Promise.resolve(axios.get(endpoints.customHls.replace("{id}", id)));
