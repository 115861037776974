import React from "react";
import { useRoutes } from "react-router-dom";
import { routeNames } from "constants";

import DashBoard from "layout/dashboard";

import Home from "pages/home";
import Events from "pages/events";
import Payments from "pages/payments";
import Settings from "pages/settings";

import Login from "pages/auth/user/login";
import Signup from "pages/auth/user/signup";

import HomePage from "pages/homePage/homePage";
import WeddingTemplate from "pages/weddingTemplate/weddingTemplate";
import NotFoundPage from "pages/notFoundPage";
import Activation from "pages/activation";
import ResetPassword from "pages/resetPassword";
import EditEvent from "pages/EditEvent";
const AppRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "",
      element: <DashBoard />,
      children: [
        {
          path: routeNames.dashBoard,
          element: <Home />,
        },
        {
          path: routeNames.event,
          element: <Events />,
        },
        {
          path: routeNames.payments,
          element: <Payments />,
        },
        {
          path: routeNames.settings,
          element: <Settings />,
        },
        {
          path: `${routeNames.editEvent}/:name/:id`,
          element: <EditEvent />,
        },
      ],
    },
    {
      path: `/:title/:id`,
      element: <WeddingTemplate />,
    },
    {
      path: "/activation/:uid/:token",
      element: <Activation />,
    },
    {
      path: "/password-reset/:uid/:token",
      element: <ResetPassword />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);
};

export default AppRoutes;
