import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { routeNames } from "constants";
import { useAppDataContext } from "hooks/useappdatacontext";
import styles from "./streamModal.module.css";

const StreamModal = ({ open, handleClose, title }) => {
  const navigate = useNavigate();

  // Contexts:
  const { regions, fetchRegions, handleProceed, userCredits, loading } =
    useAppDataContext();

  // States:
  const [formState, setFormState] = useState({
    name: title,
    region: "",
    recording: "False",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    if (regions) {
      const defaultRegion = regions.find((region) => region.name === "Mumbai");
      if (defaultRegion) {
        setFormState((prevState) => ({
          ...prevState,
          region: defaultRegion.name,
        }));
      }
    }
  }, [regions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateChannel = async () => {
    const selectedRegion = regions.find(
      (region) => region.name === formState.region
    );
    const payload = {
      ...formState,
      region: selectedRegion ? selectedRegion.region_id : "",
    };

    if (userCredits?.vid_credits > 0) {
      const des = await handleProceed(payload);
      if (des === false) {
        setError(`${selectedRegion?.name}`);
      }
      if (des === true) {
        handleClose();
      }
    }
  };

  const handleErrorModalClose = () => {
    setError("");
  };

  const renderStreamModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: 500 },
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          {userCredits && userCredits?.vid_credits > 0 ? (
            <>
              <Typography variant="h6" gutterBottom>
                Custom Stream URL
              </Typography>
              <Typography variant="h6" gutterBottom>
                Event Name: {formState.name}
              </Typography>
              <FormControl margin="normal">
                <InputLabel>Nearest Region</InputLabel>
                <Select
                  name="region"
                  value={formState.region}
                  onChange={handleChange}
                  label="Nearest Region"
                  sx={{ width: "400px" }}
                >
                  {regions?.map((region) => (
                    <MenuItem key={region.region_id} value={region.name}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl component="fieldset" margin="normal">
                <Typography variant="subtitle1" gutterBottom>
                  Recording
                </Typography>
                <RadioGroup
                  name="recording"
                  value={formState.recording}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="True"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="False"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateChannel}
                fullWidth
                sx={{ mt: 10 }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Proceed"
                )}
              </Button>
            </>
          ) : (
            <div className={styles.noCreditsTextBlock}>
              <h3 className={styles.noCreditsText}>
                Currently you have no credits
              </h3>
              <button
                className={styles.purchaseButton}
                onClick={() => {
                  navigate(routeNames.payments);
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Purchase Credits"
                )}
              </button>
            </div>
          )}
        </Box>
      </Modal>
    );
  };

  const renderStreamErrormodal = () => {
    return (
      error && (
        <div className={styles.streamErrorModal}>
          <div className={styles.streamErrorModalContent}>
            <p>
              Unable to create a Channel in <span className={styles.regionErrorText}>{error}</span>
            </p>
            <button
              onClick={handleErrorModalClose}
              className={styles.streamCloseBtn}
            >
              Close
            </button>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      {renderStreamModal()}
      {renderStreamErrormodal()}
    </>
  );
};

export default StreamModal;
