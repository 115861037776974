import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./searchBar.module.css";

const SearchBar = ({ searchQuery, handleSearchChange }) => {
  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="Search Event..."
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        className={styles.textFieldTextStyle}
      />
    </div>
  );
};

export default SearchBar;
