import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { AuthProvider } from "providers/authprovider";
import { AppDataProvider } from "providers/appdataprovider";
import { ToastProvider } from "providers/toastprovider";

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <AppDataProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </AppDataProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
