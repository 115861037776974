import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  Box,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import {
  MdDownload,
  MdPhoneCallback,
  MdDeleteForever,
  MdAccessTimeFilled,
  MdWarning,
} from "react-icons/md";
import axiosInstance from "utils/axios";
import { useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FaEdit } from "react-icons/fa";
import { MdSaveAs, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useAppDataContext } from "hooks/useappdatacontext";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import axios from "axios";
import videojs from "video.js";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { BsCalendar2DateFill } from "react-icons/bs";

import "./index.css";
import { CoupleFrame } from "../../resources/images";
import { FaLocationDot } from "react-icons/fa6";
import HLSPlayer from "../../components/hlsPlayer";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery";
import styles from "../../pages/weddingTemplate/weddingTemplate.module.css";
import EditLoadingView from "./LoadingView";
import UploadProgressPopup from "./LoadingView/uploadProgress";
const EditEvent = () => {
  const navigate = useNavigate();
  const [apiError, setApiError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const [selectedPasswordOption, setSelectedPasswordoption] = useState("");
  const [minimumPassword, setMinimumPassword] = useState("");
  const [fetchedPassword, setFetchedPassword] = useState("");

  const videoRef = useRef(null);
  const [resolution, setResolution] = useState({ width: 0, height: 0 });

  const [hlsLiveUrl, setHlsLiveUrl] = useState("");
  const [error, setError] = useState("");
  const [loadingView, setLoadingView] = useState(true);
  const [passwordVisibilty, setPasswordVisibility] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedInvitationCard, setSelectedInvitationCard] = useState(null);
  const [selectedInvitationVideo, setSelectedInvitationVideo] = useState(null);
  const [selectedOfflineVideo, setSelectedOfflineVideo] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const [selectedOption, setSelectedOption] = useState("RTMPS");
  const [popCanelToEdit, setPopUpCancelToEdit] = useState(false);
  const editor = useRef(null);
  const [viewDownloadLink, setViewDownloadLink] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadCount, setUploadCount] = useState(0); // Tracks number of uploaded chunks
  const [totalUploads, setTotalUploads] = useState(0); // Tracks total chunks to upload
  const [progress, setProgress] = useState(0); // Tracks upload progress percentage
  const [offlineVideoDimensions, setOfflineVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const vidRef = useRef(null);

  const handleFileChangeForOffline = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setSelectedOfflineVideo(file);
      setVideoSrc(objectUrl);
    }
  };

  //state to cahnge the input data after clicking save button on every input field this satate data will be posted to event data
  const [content, setContent] = useState({
    userName: "",
    contact: "",
    eventName: "",
    template: "",
    venue: "",
    venue_date: "",
    venue_time: "",
    template_desc: "",
  });

  const {
    eventData,
    setEventData,
    getAllEvents,
    getEvent,
    updateEventData,
    getChannelData,
    singleChannelData,
    singleEventData,
    setSingleEventData,
    updateCustomHlsApi,
    showLoader,
    loading,
    setShowLoader,
  } = useAppDataContext();

  const [popCanelTheEditOrConfirm, setpopCanelTheEditOrConfirm] =
    useState(false);
  const playerRef = useRef(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [textEditor, setTextEditor] = useState({
    userName: false,
    contact: false,
    eventName: false,
    template: false,
    venue: false,
    venueDate: false,
    venueTime: false,
  });

  const [option, setOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  //useEffect to fetch data feom backend with id retrived from routing
  const { name, id } = useParams();

  useEffect(() => {
    handleClickOpenEdit();
  }, []);
  const [dwnloadlink, setDownloadlink] = useState(false);

  const handleClickOpenEdit = async () => {
    const das = await getEvent(name, id);
    await handleGetChannelData(`${name}/${id}`);

    setSingleEventData(das);
    setSelectedRowId(`${name}/${id}`);
    setOpenEdit(true);
    // Reset player before reinitializing
    if (playerRef.current) {
      playerRef.current.dispose();
      playerRef.current = null;
    }
    setTimeout(initializePlayer, 1000);
  };
  useEffect(() => {
    if (singleChannelData?.recorded_mp4_link) {
      setDownloadlink(true);
    }
  }, [singleChannelData]);
  useEffect(() => {
    setContent({
      userName: singleEventData?.name,
      contact: singleEventData?.contact,
      eventName: singleEventData?.title,
      template: singleEventData?.template,
      venue: singleEventData?.venue,
      venue_date: singleEventData?.venue_date,
      venue_time: singleEventData?.venue_time,
      template_desc: singleEventData?.template_desc,
    });
  }, [singleEventData]);

  //hook to define eventData hook variables with singleEventData hooks after page loaded
  useEffect(() => {
    // setPopUpCancelToEdit(false);
    setEventData({
      name: singleEventData?.name,
      contact: singleEventData?.contact || "",
      password: singleEventData?.password || "",
      title: singleEventData?.title || "",
      venue: singleEventData?.venue || "",
      venue_date: singleEventData?.venue_date || "",
      venue_time: singleEventData?.venue_time || "",
      live_vidlink: singleEventData?.live_vidlink || "",
      banner: singleEventData?.banner || null,
      invitation_card: singleEventData?.invitation_card || null,
      invitation_video: singleEventData?.invitation_video || null,
      offline_video: singleEventData?.offline_video || null,
      images: singleEventData?.images || [],
      is_secure: singleEventData?.is_secure || "",
      template: singleEventData?.template || "",
      template_desc: singleEventData.template_desc || "",
    });
  }, [singleEventData]);

  //video generating funtion
  const handlerToGenerateVideoLink = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_MEDIA_URL}/api/stream/initate_mp4/${id}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred");
      console.error(error);
    }
  };

  //video download with link handler
  const handleDownloadVideo = (videoUrl) => {
    const link = document.createElement("a");
    link.href = videoUrl;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //video download event handler
  const handlerToDownloadVideo = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_MEDIA_URL}/api/stream/get_dwnlink_mp4/${id}`
      );

      if (response.status === 200) {
        handleDownloadVideo(response.data.Link);
      }
    } catch (error) {
      setApiError("Failed to fetch download link. Please try again.");
    }
  };

  //component to render live video based on available link
  const renderLiveVideoComponent = (hider) => {
    // Extract YouTube video and live IDs
    const videoId = extractYouTubeVideoId(eventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(eventData?.live_vidlink);

    // Determine the appropriate video URL based on the type
    const videoUrl = videoId
      ? `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`
      : "";
    const liveUrl = liveId
      ? `https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`
      : "";

    const presentDate = new Date();
    const futureDate = new Date(singleChannelData?.dwn_expiry_date); //singleChannelData?.dwn_expiry_date

    const differenceInMilliseconds = futureDate - presentDate;
    const differenceInDays = Math.ceil(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    return (
      <>
        {videoUrl && (
          <div
            className={`${
              !hider ? "box-border-styling" : "box-border-styling-80"
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `${
                  hider &&
                  "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')"
                }`,
              }}
              className={`${hider && "video-container"}`}
            >
              {!hider && <h4>Live video</h4>}
              <iframe
                width="inherit"
                height="inherit"
                style={{ padding: "70px 0px !important", width: "350px" }}
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
              ></iframe>
              {!hider && (
                <input
                  type="text"
                  style={{ marginTop: "5px" }}
                  value={singleEventData?.live_vidlink}
                  onChange={(e) =>
                    setSingleEventData({
                      ...singleEventData,
                      live_vidlink: e.target.value,
                    })
                  }
                  className="edit-modal-input"
                />
              )}
            </div>
          </div>
        )}

        {liveUrl && !videoUrl && (
          <div
            className={`${
              !hider ? "box-border-styling" : "box-border-styling-80"
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `${
                  hider &&
                  "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')"
                }`,
              }}
              className={`${hider && "video-container-live"} `}
            >
              <iframe
                width="inherit"
                height="inherit"
                src={liveUrl}
                frameBorder="3"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Live"
              ></iframe>
              {!hider && (
                <input
                  style={{ marginTop: "10px" }}
                  type="text"
                  value={singleEventData?.live_vidlink}
                  onChange={(e) =>
                    setSingleEventData({
                      ...singleEventData,
                      live_vidlink: e.target.value,
                    })
                  }
                  className="edit-modal-input"
                />
              )}
            </div>
          </div>
        )}

        {hlsLiveUrl && !videoUrl && !liveUrl && (
          <div
            className={`${
              !hider ? "box-border-styling" : "box-border-styling-80"
            }`}
          >
            <div
              style={{
                backgroundImage: `${
                  hider &&
                  "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')"
                }`,
              }}
              className="videoContainer"
            >
              <HLSPlayer preview={true} />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!hider && (
                <>
                  {singleChannelData?.recording ? (
                    <>
                      {singleChannelData?.recording_playback_url !==
                      eventData?.live_vidlink ? (
                        <button
                          onClick={() =>
                            handleTriggerHlsApi(singleChannelData?.id)
                          }
                          className="updateHlsLiveBtn"
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Update Recorded URL"
                          )}
                        </button>
                      ) : (
                        <div className="link-input-and-download-btn">
                          {/* {viewDownloadLink && (
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                boxSizing: "border-box",
                                padding: "10px",
                                marginBottom: "5px",
                              }}
                              value={eventData?.live_vidlink}
                              disabled
                            />
                          )} */}
                          {/* {!viewDownloadLink && (
                            <>
                              {futureDate < presentDate && (
                                <button
                                  className="download-button"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#4CAF50",
                                    width: "120px",
                                    justifySelf: "center",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    setViewDownloadLink(!viewDownloadLink);
                                  }}
                                >
                                  View Link
                                </button>
                              )}
                            </>
                          )} */}

                          {singleChannelData?.recordLinksent === false && (
                            <button
                              onClick={() =>
                                handlerToGenerateVideoLink(
                                  singleChannelData?.id
                                )
                              }
                              style={{
                                backgroundColor: "#4CAF50",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                justifySelf: "center",
                              }}
                              className="download-button"
                            >
                              Genreate Link
                            </button>
                          )}

                          {dwnloadlink === true ? (
                            <>
                              {differenceInDays >= -0 && (
                                <>
                                  <button
                                    onClick={() =>
                                      handlerToDownloadVideo(
                                        singleChannelData?.id
                                      )
                                    }
                                    style={{
                                      backgroundColor: "#4CAF50",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      justifySelf: "center",
                                    }}
                                    className="download-button"
                                  >
                                    <MdDownload fontSize={18} /> Video
                                  </button>
                                  <p
                                    style={{
                                      color: "red",
                                      margin: "8px 0px 0px 0px",
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <MdWarning className="location-icon" />{" "}
                                    Download Link will be expired in{" "}
                                    {differenceInDays}{" "}
                                    {differenceInDays === 1 ? "day" : "days"}{" "}
                                  </p>
                                </>
                              )}
                              {differenceInDays < -0 && (
                                <p
                                  style={{
                                    color: "red",
                                    margin: "8px 0px 0px 0px",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <MdWarning className="location-icon" />{" "}
                                  Download Link expired
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              {singleChannelData?.recordLinksent && (
                                <button
                                  disabled
                                  style={{
                                    backgroundColor: "#007bff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    justifySelf: "center",
                                  }}
                                  className="download-button"
                                >
                                  Generating...
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    singleChannelData?.playback_url !==
                      eventData.live_vidlink && (
                      <input
                        type="text"
                        value={singleEventData?.live_vidlink}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const extractedUrl = extractHlsUrl(newValue);
                          setHlsLiveUrl(extractedUrl);
                          setSingleEventData({
                            ...singleEventData,
                            live_vidlink: newValue,
                          });
                        }}
                        className="edit-modal-input"
                        placeholder="Enter live video URL"
                      />
                    )
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const extractYouTubeVideoId = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return match && match[7]?.length === 11 ? match[7] : null;
  };

  const extractYouTubeLiveId = (url) => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = url?.match(regExp);
    return match ? match[1] : null;
  };

  const handleTriggerHlsApi = async (channelId) => {
    const { success, message } = await updateCustomHlsApi(channelId); // Use object destructuring
    if (success === true) {
      setOpenEdit(false);
      toast.success(message);
    } else {
      toast.error(message);
    }
  };

  const editingValidator = () => true;

  // singleEventData.name !== eventData.name ||
  // singleEventData.contact !== eventData.contact ||
  // singleEventData.title !== eventData.title ||
  // singleEventData.template !== eventData.template ||
  // // singleEventData.template_desc !== eventData.template_desc ||
  // singleEventData.venue !== eventData.venue ||
  // singleEventData.venue_time !== eventData.venue_time ||
  // singleEventData.venue_date !== eventData.venue_date ||
  // eventData.is_secure !== "" ||
  // selectedBanner  ||
  // eventData.banner !== singleEventData.banner ||
  // selectedInvitationCard ||
  // eventData.invitation_card !== singleEventData.invitation_card ||
  // selectedInvitationVideo ||
  // eventData.invitation_video !== singleEventData.invitation_video ||
  // selectedOfflineVideo ||
  // eventData.offline_video !== singleEventData.offline_video;

  const extractHlsUrl = (url) => {
    const regExp = /(https?:\/\/[^\s]+\.m3u8)/;
    const match = url?.match(regExp);
    return match ? match[0] : null;
  };

  useEffect(() => {
    setHlsLiveUrl("");
    if (singleEventData?.live_vidlink) {
      setHlsLiveUrl("");
      const hlsLive = extractHlsUrl(singleEventData.live_vidlink);
      if (hlsLive) {
        setHlsLiveUrl(hlsLive);
      }
    }
  }, [singleEventData?.live_vidlink]);

  useEffect(() => {
    if (eventData?.is_secure) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  }, [eventData?.is_secure]);

  const handleSubmitEdit = async () => {
    if (true) {
      if (editingValidator()) {
        setpopCanelTheEditOrConfirm(false);
        setPopUpCancelToEdit(false);
        setOpenEdit(false);
        const formData = new FormData();

        // Append only the fields that have been changed
        if (eventData.name !== singleEventData.name) {
          formData.append("name", eventData.name || "");
        }
        if (eventData.contact !== singleEventData.contact) {
          formData.append("contact", eventData.contact || "");
        }
        if (eventData.password !== singleEventData.password) {
          formData.append("password", eventData.password || "");
        }
        if (eventData.title !== singleEventData.title) {
          formData.append("title", eventData.title || "");
        }
        if (eventData.venue !== singleEventData.venue) {
          formData.append("venue", eventData.venue || "");
        }
        if (eventData.venue_date !== singleEventData.venue_date) {
          formData.append("venue_date", eventData.venue_date || "");
        }
        if (eventData.venue_time !== singleEventData.venue_time) {
          formData.append("venue_time", eventData.venue_time || "");
        }
        if (eventData.template !== singleEventData.template) {
          formData.append("template", eventData.template || "");
        }
        if (eventData.template_desc !== singleEventData.template_desc) {
          formData.append("template_desc", eventData.template_desc || "");
        }
        if (selectedBanner) {
          formData.append("banner", selectedBanner);
        }
        if (eventData?.banner === null && selectedBanner === null) {
          formData.append("banner", "");
        }
        if (selectedInvitationCard) {
          formData.append("invitation_card", selectedInvitationCard);
        }
        if (
          selectedInvitationCard === null &&
          eventData?.invitation_card === null
        ) {
          formData.append("invitation_card", "");
        }

        if (
          selectedInvitationVideo === null &&
          eventData?.invitation_video === null
        ) {
          formData.append("invitation_video", "");
        }

        if (
          selectedOfflineVideo === null &&
          eventData?.offline_video === null
        ) {
          formData.append("offline_video", "");
        }
        if (eventData.live_vidlink) {
          formData.append("live_vidlink", eventData.live_vidlink || "");
        }

        if (eventData.is_secure !== singleEventData.is_secure) {
          formData.append("is_secure", eventData.is_secure ? true : false);
        }

        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);

        try {
          setShowLoader(true);
          formData.append("prlink", selectedRowId);
          await updateEventData(formData);
          setOpenEdit(false);
          setFetchedPassword("");
          getAllEvents();
          setShowLoader(false);
          setTextEditor({
            userName: false,
            contact: false,
            eventName: false,
            template: false,
            venue: false,
            venueDate: false,
            venueTime: false,
          });
          setContent({
            userName: "",
            contact: "",
            eventName: "",
            template: "",
            venue: "",
            venue_date: null,
            venue_time: null,
            template_desc: "",
          });
          setPopUpCancelToEdit(false);
          setpopCanelTheEditOrConfirm(false);
          setOpenEdit(false);
          setSelectedBanner(null);
          setSelectedInvitationCard(null);
          setSelectedInvitationVideo(null);
          setSelectedOfflineVideo(null);
        } catch (error) {
          console.error("Error updating event data:", error);
        }
      }
    } else if (
      textEditor.userName &&
      textEditor.venue &&
      textEditor.contact &&
      textEditor.template &&
      textEditor.title &&
      textEditor.venueDate &&
      textEditor.venueTime
    ) {
      toast.error("Please Save or Cancel Edited sections");
    }
  };

  //tag validator
  const cleanHtml = (html) => {
    const isTrue = DOMPurify.sanitize(html);
    if (isTrue) {
      return parse(html);
    } else {
      return html;
    }
  };

  //text editor
  const renderTextEditor = (inputType) => {
    return (
      <>
        <JoditEditor
          ref={editor}
          value={content?.[inputType]}
          onChange={(newContent) => {
            if (inputType === "venue") {
              if (stripHtmlTags(newContent).length <= 100) {
                setContent({ ...content, venue: newContent });
              }
            } else {
              setContent({ ...content, [inputType]: newContent });
            }
          }}
        />
      </>
    );
  };

  // hls player options
  const playerOptions = {
    autoplay: true,
    controls: true,
    fluid: true,
    preload: "auto",
    sources: [
      {
        src: hlsLiveUrl || "",
        type: "application/x-mpegURL",
      },
    ],
  };

  const initializePlayer = () => {
    if (playerRef.current) {
      playerRef.current = null;
    }
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, playerOptions, () => {
        setLoadingView(false);
      });

      let retryCount = 0; // Initialize retry counter

      // Add error handling
      playerRef.current.on("error", (error) => {
        console.error("Video.js Error:", error);

        if (error.code === 3) {
          // MEDIA_ERR_DECODE
          if (retryCount < 3) {
            retryCount++; // Increment retry counter
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
            }, 5000);
          } else {
            setError("Event stream is not live.");
          }
        } else {
          if (retryCount < 3) {
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
              retryCount++; // Increment retry counter
            }, 3000);
          } else {
            setError("Cant Access Stream!! Please Reload the Page");
            // window.location.reload()
          }
        }
      });
      setError("");
    }
  };

  useEffect(() => {
    setTimeout(initializePlayer, 1000);

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [hlsLiveUrl]);

  //tags remover
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  //
  const previewUrl = (file) => URL.createObjectURL(file);

  //
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  //
  const handleFileChange = (setter) => (event) => {
    const file = event.target.files[0];
    setter(file);
  };

  const handleFileDelete = (setter) => (event) => {
    setter(null);
    if (setter === setSelectedBanner) {
      setEventData({
        ...eventData,
        banner: null,
      });
    }
    if (setter === setSelectedInvitationCard) {
      setEventData({
        ...eventData,
        invitation_card: null,
      });
    }
    if (setter === setSelectedInvitationVideo) {
      setEventData({
        ...eventData,
        invitation_video: null,
      });
    }
    if (setter === setSelectedOfflineVideo) {
      setEventData({
        ...eventData,
        offline_video: null,
      });
    }
  };

  // const handleImagesChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedImages((prevImages) => [...prevImages, ...files]);
  // };

  const handleImagesChange = async (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.filter((file) => {
      return !selectedImages.some(
        (selectedImage) => selectedImage.name === file.name
      );
    });
    setSelectedImages((prevImages) => [...prevImages, ...newFiles]);
    await postImagesToAnotherAPI(newFiles);
  };

  const handleGetChannelData = async (id) => {
    await getChannelData(id);
  };

  const postImagesToAnotherAPI = async (images) => {
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    try {
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/events/add_event_image/${singleEventData.id}/`,
        formData
      );

      if (response.status === 200) {
        // Assuming response.data.images contains the newly uploaded images
        const newUploadedImages = response.data;

        // Filter out duplicates based on image names or ids
        const uniqueNewImages = newUploadedImages.filter(
          (newImage) =>
            !eventData.images.some(
              (existingImage) => existingImage.image === newImage.image
            )
        );

        // Merge existing and unique new images into the eventData
        setEventData((prevEventData) => ({
          ...prevEventData,
          images: [...prevEventData.images, ...uniqueNewImages],
        }));

        // Optionally clear the selectedImages after successful upload
        setSelectedImages([]);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      console.error("Error uploading images:", error);
    }
  };

  const deleteImage = async (imageToDelete) => {
    setShowLoader(true);
    try {
      const data = {
        event_id: singleEventData?.id,
        image_id: imageToDelete,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/events/delete_event_image/`,
        data
      );

      if (res.status === 200) {
        setEventData({
          ...eventData,
          images: eventData.images.filter(
            (image) => image?.id !== imageToDelete
          ),
        });
        setSelectedImages((prevImages) =>
          prevImages.filter((image) => image?.id !== imageToDelete)
        );
        setShowLoader(false);
      } else {
        setShowLoader(false);
        console.error("Failed to delete image:", res.statusText);
      }
    } catch (error) {
      setShowLoader(false);
      console.error("Error deleting image:", error);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Function to close edit modal
  const handleCloseEdit = () => {
    if (
      // !textEditor.userName &&
      // !textEditor.venue &&
      // !textEditor.contact &&
      // !textEditor.title &&
      // !textEditor.venueDate &&
      // !textEditor.venueTime &&
      // !textEditor.template
      true
    ) {
      if (editingValidator()) {
        setpopCanelTheEditOrConfirm(true);
      } else {
        setpopCanelTheEditOrConfirm(false);
        setTextEditor({
          userName: false,
          contact: false,
          eventName: false,
          template: false,
          venue: false,
          venueDate: false,
          venueTime: false,
        });
        setOpenEdit(false);
        setSelectedRowId(null);
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      }
    } else {
      toast.error("Please Save or Cancel Edited section");
    }

    // setHlsLiveUrl("");//due to this set after closing edit event then hls link is erasing...

    // Dispose of player on modal close
  };

  const handlerEditEvent = () => {
    if (
      // !textEditor.userName &&
      // !textEditor.venue &&
      // !textEditor.contact &&
      // !textEditor.title &&
      // !textEditor.venueDate &&
      // !textEditor.venueTime
      true
    ) {
      if (editingValidator()) {
        setPopUpCancelToEdit(true);
      } else {
        setTextEditor({
          userName: false,
          contact: false,
          eventName: false,
          template: false,
          venue: false,
          venueDate: false,
          venueTime: false,
        });
        setPopUpCancelToEdit(false);
        setOpenEdit(false);
        setSelectedRowId(null);
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      }
    } else {
      toast.error("Please Save or Cancel Edited section");
    }

    // setHlsLiveUrl("");//due to this set after closing edit event then hls link is erasing...

    // Dispose of player on modal close
  };
  const handleEditSelection = () => {
    setEventData({
      ...eventData,
      is_secure: !eventData?.is_secure,
    });
  };
  const handleEditPassword = (e) => {
    const newpass = e.target.value;
    setEventData({
      ...eventData,
      password: newpass,
    });
  };

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    setEventData((prev) => ({
      ...prev,
      is_secure: !eventData?.is_secure,
    }));

    // resetForm();
  };

  const handleSelectedOptionChange = async (event) => {
    const value = event.target.value;
    setSelectedPasswordoption(value);
    resetForm();

    if (value === "generatePassword") {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_MEDIA_URL}/api/events/create_Secret/`
        );
        setFetchedPassword(response.data.message);
        setEventData((prev) => ({
          ...prev,
          password: response.data.message,
        }));
      } catch (error) {
        setApiError("Failed to fetch password. Please try again.");
      }
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setEventData((prev) => ({
      ...prev,
      password: value,
    }));
    setFetchedPassword(value);
    validatePassword(value);
  };

  const validatePassword = (value) => {
    if (value.length > 0 && value.length <= 8) {
      setMinimumPassword("It's a Valid Password");
    } else if (value.length === 0) {
      setMinimumPassword("");
    } else {
      setPasswordError("Password must be at least 8 characters long.");
    }
  };

  const resetForm = () => {
    setEventData((prev) => ({
      ...prev,
      password: "",
    }));
    setPasswordError("");
    setApiError("");
    setApiSuccess("");
    setFetchedPassword("");
  };

  const renderEditPassword = () => {
    return (
      <div>
        {singleEventData?.is_secure ? (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(eventData?.is_secure)}
                  onChange={handleEditSelection}
                />
              }
              label="Protect with password"
              className="label-text-style"
            />
            {showPassword && (
              <Box>
                <TextField
                  type="text"
                  value={eventData?.password}
                  onChange={(e) => {
                    const password = e.target.value;
                    // Prevent empty value or too long
                    if (password.length > 0 && password.length >= 8) {
                      handleEditPassword(e);
                    }
                  }}
                  fullWidth
                  inputProps={{ maxLength: 8, minLength: 1 }}
                />
                {apiError && <Typography color="error">{apiError}</Typography>}
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(eventData?.is_secure)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Protect with password"
              className="label-text-style"
            />
            {eventData?.is_secure && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl
                  component="fieldset"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <FormLabel component="div">Select an option</FormLabel>
                  <RadioGroup
                    value={selectedPasswordOption}
                    onChange={handleSelectedOptionChange}
                    sx={{
                      height: "40px",
                      display: "flex",
                    }}
                  >
                    <FormControlLabel
                      value="generatePassword"
                      control={<Radio />}
                      label="Generate password"
                    />
                    <FormControlLabel
                      value="customPassword"
                      control={<Radio />}
                      label="Set custom password"
                    />
                  </RadioGroup>
                </FormControl>

                {selectedPasswordOption === "generatePassword" && (
                  <Box>
                    <TextField
                      type="text"
                      placeholder="Auto-generated password"
                      value={fetchedPassword}
                      fullWidth
                      slotProps={{ readOnly: true }}
                    />
                    {apiError && (
                      <Typography color="error">{apiError}</Typography>
                    )}
                  </Box>
                )}

                {selectedPasswordOption === "customPassword" && (
                  <Box>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type={!passwordVisibilty ? "text" : "password"}
                        placeholder="Enter password"
                        value={fetchedPassword}
                        onChange={handlePasswordChange}
                        fullWidth
                        error={Boolean(passwordError)}
                        helperText={passwordError}
                        inputProps={{ maxLength: 8, minLength: 1 }}
                      />
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          position: "absolute",
                          right: 10,
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => {
                          setPasswordVisibility(!passwordVisibilty);
                        }}
                      >
                        {passwordVisibilty ? (
                          <MdVisibility size={25} />
                        ) : (
                          <MdVisibilityOff size={25} />
                        )}
                      </button>
                    </div>

                    {minimumPassword && (
                      <p style={{ color: "green", marginTop: "2px" }}>
                        {minimumPassword}
                      </p>
                    )}

                    {apiError && (
                      <Typography color="error">{apiError}</Typography>
                    )}
                    {apiSuccess && (
                      <Typography color="success.main">{apiSuccess}</Typography>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </div>
    );
  };

  const handleToCancelTheEvent = () => {
    setOpenEdit(false);
    setPopUpCancelToEdit(false);
    setpopCanelTheEditOrConfirm(false);
    setSelectedBanner(null);
    setSelectedInvitationCard(null);
    setSelectedInvitationVideo(null);
    setSelectedOfflineVideo(null);
  };

  const handleEventEditCancel = () => {
    setPopUpCancelToEdit(false);
    setOpenEdit(true);
  };

  const handleEventEditConfirmation = () => {
    if (selectedInvitationVideo) {
      setShowLoader(true);
      const apiUrl = `${process.env.REACT_APP_MEDIA_URL}/api/events/inviVideo_chunk/`;
      uploadFileInChunks(selectedInvitationVideo, apiUrl);
    }
    if (selectedOfflineVideo) {
      setShowLoader(true);
      const apiUrl = `${process.env.REACT_APP_MEDIA_URL}/api/events/offline_chunk/`;
      uploadFileInChunks(selectedOfflineVideo, apiUrl);
    }
    setEventData((prevData) => {
      const newEventData = {
        ...prevData,
        name: content.userName,
        contact: content.contact,
        title: content.eventName,
        template: content.template,
        template_desc: content.template_desc,
        venue: content.venue,
        venue_date: content.venue_date,
        venue_time: content.venue_time,
      };
      if (!selectedOfflineVideo && !selectedInvitationVideo) {
        handleSubmitEdit();
      }
      return newEventData;
    });
  };

  const renderEdtiedContents = () => (
    <DialogContent>
      <p style={{ marginBottom: "5px", textDecoration: "underline" }}>
        Confirm List of Changes
      </p>
      <ol>
        {singleEventData.name !== eventData.name && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            Useranme has been Changed from{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(singleEventData.name)}
            </span>
            to
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(eventData.name)}
            </span>
          </p>
        )}

        {singleEventData.contact !== eventData.contact && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            {" "}
            Contact Number has been Changed from{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(singleEventData.contact)}
            </span>{" "}
            to{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(eventData.contact)}
            </span>
          </p>
        )}

        {singleEventData.template !== eventData.template && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            Event Type has been Changed from{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(singleEventData.template)}
            </span>{" "}
            to{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(eventData.template)}
            </span>
          </p>
        )}

        {singleEventData.title !== eventData.title && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            Event Name has been Changed from{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(singleEventData.title)}
            </span>{" "}
            to{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(eventData.title)}
            </span>
          </p>
        )}

        {singleEventData.venue !== eventData.venue && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            Venue has been Changed from{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(singleEventData.venue)}
            </span>{" "}
            to{" "}
            <span style={{ margin: "0px 5px" }}>
              {cleanHtml(eventData.venue)}
            </span>
          </p>
        )}

        {singleEventData.venue_date !== eventData.venue_date && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            Venue Date has been Changed from
            {singleEventData.venue_date}
            to {eventData.venue_date}
          </p>
        )}

        {singleEventData.venue_time !== eventData.venue_time && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            Venue Time has been Changed from{singleEventData.venue_time}{" "}
            {eventData.venue_time}
          </p>
        )}

        {(selectedBanner || eventData.banner !== singleEventData.banner) && (
          <p style={{ margin: "4px 0px" }}>You Have Changed the Event Banner</p>
        )}

        {(selectedInvitationCard ||
          eventData.invitation_card !== singleEventData.invitation_card) && (
          <p style={{ margin: "4px 0px" }}>You Have Changed Invitation Card</p>
        )}

        {selectedInvitationVideo && <p>You Have Changed Invitation Video</p>}

        {(selectedOfflineVideo ||
          eventData.offline_video !== singleEventData.offline_video) && (
          <p style={{ margin: "4px 0px" }}>You Have Changed offline video</p>
        )}

        {eventData.is_secure !== "" && (
          <p style={{ display: "flex", margin: "4px 0px" }}>
            Event protection has been{" "}
            {eventData.is_secure ? (
              <span style={{ color: "green", margin: "0px 0px 0px 2px" }}>
                Enabled
              </span>
            ) : (
              <span style={{ color: "red", margin: "0px 0px 0px 2px" }}>
                Disabled
              </span>
            )}
          </p>
        )}
      </ol>
    </DialogContent>
  );

  const renderCancelSection = () => (
    <Dialog fullWidth open={popCanelTheEditOrConfirm}>
      <DialogTitle
        sx={{ textAlign: "center", marginBottom: " 10px", fontWeight: "700" }}
      >
        Cancel Edited Inputs
      </DialogTitle>
      {renderEdtiedContents()}
      <DialogActions
        sx={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          variant="outlined"
          color="danger"
          sx={{ color: "red" }}
          onClick={handleToCancelTheEvent}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="success"
          sx={{ color: "green" }}
          onClick={handleEventEditConfirmation}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderConfirmSection = () => {
    return (
      <Dialog fullWidth open={popCanelToEdit}>
        <DialogTitle
          sx={{ textAlign: "center", marginBottom: " 10px", fontWeight: "700" }}
        >
          Confirmation Your Edited Section
        </DialogTitle>
        {renderEdtiedContents()}
        <DialogActions
          sx={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            sx={{ color: "red" }}
            variant="outlined"
            color="danger"
            onClick={handleEventEditCancel}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="success"
            sx={{ color: "green" }}
            onClick={handleEventEditConfirmation}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderNavigation = (mobile) => {
    const footerStyle = mobile
      ? { borderTopLeftRadius: "30px", borderTopRightRadius: "30px" }
      : {};

    return (
      <div
        style={{
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f8ff",
          ...footerStyle,
        }}
      >
        {cleanHtml(eventData?.title)}
      </div>
    );
  };

  const renderSpecificBannerView = () => {
    switch (singleEventData.template) {
      case "Birthday":
      case "Saree Ceremony":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728558003/5395531_fkgvby.jpg";
      case "Marriage":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728635524/japanese-paper-textures_vhxore.jpg";
      case "Cultural Event":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728636985/frame-with-flowers-it-that-says-golden_nkloxz.jpg";
      case "Family Reunion":
      case "Party":
        return "";
      default:
        return CoupleFrame;
    }
  };

  const renderBannerImage = () => {
    return (
      <>
        {selectedBanner ? (
          <div id="home" className={styles.banner_block}>
            <img
              src={previewUrl(selectedBanner)}
              alt="Banner Preview"
              style={{
                width: "80%",
                borderRadius: "8px",
              }}
            />
          </div>
        ) : eventData?.banner !== null ? (
          <div className={styles.frameContainer}>
            <img
              src={eventData?.banner}
              alt="Frame"
              style={{ width: "70%" }}
              className={styles.frameImage}
            />
          </div>
        ) : (
          <div className={styles.frameContainer}>
            <img
              src={renderSpecificBannerView()}
              alt="Frame"
              style={{ width: "70%" }}
              className={styles.frameImage}
            />
            <p className={styles.frameContent}>{cleanHtml(eventData?.title)}</p>
          </div>
        )}
      </>

      // (
      //   <div className={styles.frameContainer}>
      //     {/* <img src={CoupleFrame} alt="Frame" className={styles.frameImage} /> */}
      //     <img
      //       src="https://res.cloudinary.com/dmhx6frzp/image/upload/v1728558003/5395531_fkgvby.jpg"
      //       alt="Frame"
      //       className={styles.frameImage}
      //     />
      //     {/* <p className={styles.frameContent}>
      //     Danial <br />& <br />
      //     Floes
      //     </p> */}
      //     <p className={styles.frameContent}>{cleanHtml(eventData?.title ? eventData?.title : singleEventData?..title)}</p>
      //   </div>
      // )
      //   }
      // </>
    );
  };

  const renderInvitationSection = () => {
    //video resolution finder
    const handleLoadedMetadata = () => {
      const video = videoRef.current;
      if (video) {
        const { videoWidth, videoHeight } = video;
        setResolution({ width: videoWidth, height: videoHeight });
      }
    };
    const isPortrait = resolution.height >= resolution.width;

    return (
      <>
        {selectedInvitationCard ? (
          <div id="invitation">
            {selectedInvitationCard && (
              <img
                src={previewUrl(selectedInvitationCard)}
                className={styles.invitation_card_img}
                alt="Invitation card"
              />
            )}
          </div>
        ) : (
          <>
            {eventData && eventData.invitation_card ? (
              <div id="invitation">
                <img
                  src={eventData.invitation_card}
                  className={styles.invitation_card_img}
                  alt="Invitation card"
                />
              </div>
            ) : (
              <div className="invitation-frame">
                <div>
                  <p
                    className={styles.marginBottom}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FaLocationDot className="location-icon" />

                    {cleanHtml(eventData?.venue)}
                  </p>

                  <div
                    className="time-date"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <BsCalendar2DateFill className="location-icon" />
                      <div>{eventData?.venue_date}</div>
                    </div>

                    <p style={{ display: "flex", alignItems: "center" }}>
                      <MdAccessTimeFilled className="location-icon" />{" "}
                      <span>
                        {new Date(
                          `1970-01-01T${eventData?.venue_time}`
                        ).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </span>
                    </p>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "8px 0px 0px 0px",
                    }}
                  >
                    <MdPhoneCallback className="location-icon" />
                    {eventData?.contact}
                  </p>
                  <div style={{ margin: "8px 0px 0px 0px" }}>
                    Invited By {cleanHtml(eventData?.template_desc)}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {selectedInvitationVideo && (
          <>
            {isPortrait ? (
              <div
                className="video-container2"
                style={{ transform: "rotate(90deg)" }}
              >
                <video
                  style={{
                    borderRadius: "8px",
                    transform: "rotate(-90deg)",
                  }}
                  className="padding-responsive-mobile-video"
                  ref={videoRef}
                  onLoadedMetadata={handleLoadedMetadata}
                  controls
                >
                  <source
                    style={{ borderRadius: "8px" }}
                    src={previewUrl(selectedInvitationVideo)}
                    type="video/mp4"
                  />
                </video>
              </div>
            ) : (
              <div className="video-container2">
                <video
                  style={{ borderRadius: "8px" }}
                  ref={videoRef}
                  onLoadedMetadata={handleLoadedMetadata}
                  controls
                >
                  <source
                    src={previewUrl(selectedInvitationVideo)}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </>
        )}

        {eventData &&
          eventData.invitation_video &&
          selectedInvitationVideo === null && (
            <>
              <div className="video-container2">
                <video
                  style={{
                    borderRadius: "8px",
                  }}
                  // className="padding-responsive-mobile-video"
                  // ref={videoRef}
                  // onLoadedMetadata={handleLoadedMetadata}
                  controls
                >
                  <source src={eventData.invitation_video} type="video/mp4" />
                </video>
              </div>
            </>
          )}
      </>
    );
  };

  // const rederSelectedVideoPlayer = () => {
  //   //video resolution finder
  //   const handleLoadedMetadata = () => {
  //     const video = videoRef.current;
  //     if (video) {
  //       const { videoWidth, videoHeight } = video;
  //       setResolution({ width: videoWidth, height: videoHeight });
  //     }
  //   };
  //   const isPortrait = resolution.height >= resolution.width;

  //   return (
  //     <>
  //       {selectedInvitationCard? (
  //         <div id="invitation">
  //           <div>
  //             {/* <p style={{ fontSize: "14px" }}>Invitation</p> */}
  //             {/* <div className={styles.invitation_media_block}>
  //               <div className={styles.invitationImageBlock}> */}
  //             <div>
  //               <div>
  //                 {selectedInvitationCard && (
  //                   <img
  //                     src={previewUrl(selectedInvitationCard)}
  //                     className={styles.invitation_card_img}
  //                     alt="Invitation card"
  //                   />
  //                 )}
  //               </div>

  //             </div>
  //           </div>
  //         </div>
  //       ) : (
  //         <div className="invitation-frame">
  //           <div>
  //             <p
  //               className={styles.marginBottom}
  //               style={{
  //                 display: "flex",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <FaLocationDot className="location-icon" />

  //               {cleanHtml(eventData?.venue)}
  //             </p>

  //             <div
  //               className="time-date"
  //               style={{
  //                 display: "flex",
  //                 justifyContent: "space-between",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <div style={{ display: "flex", alignItems: "center" }}>
  //                 <BsCalendar2DateFill className="location-icon" />
  //                 <div>{eventData?.venue_date}</div>
  //               </div>

  //               <p style={{ display: "flex", alignItems: "center" }}>
  //                 <MdAccessTimeFilled className="location-icon" />{" "}
  //                 <span>
  //                   {new Date(
  //                     `1970-01-01T${eventData?.venue_time}`
  //                   ).toLocaleTimeString([], {
  //                     hour: "numeric",
  //                     minute: "2-digit",
  //                     hour12: true,
  //                   })}
  //                 </span>
  //               </p>
  //             </div>
  //             <p
  //               style={{
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 margin: "8px 0px 0px 0px",
  //               }}
  //             >
  //               <MdPhoneCallback className="location-icon" />
  //               {eventData?.contact}
  //             </p>
  //             <div style={{ margin: "8px 0px 0px 0px" }}>
  //               Invited By {cleanHtml(eventData?.template_desc)}
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //       {selectedInvitationVideo ? (
  //         <>
  //           {isPortrait ? (
  //             <div
  //               className="video-container2"
  //               style={{ transform: "rotate(90deg)" }}
  //             >
  //               <video
  //                 style={{
  //                   borderRadius: "8px",
  //                   transform: "rotate(-90deg)",
  //                 }}
  //                 className="padding-responsive-mobile-video"
  //                 ref={videoRef}
  //                 onLoadedMetadata={handleLoadedMetadata}
  //                 controls
  //               >
  //                 <source
  //                   style={{ borderRadius: "8px" }}
  //                   src={previewUrl(selectedInvitationVideo)}
  //                   type="video/mp4"
  //                 />
  //               </video>
  //             </div>
  //           ) : (
  //             <div className="video-container2">
  //               <video
  //                 style={{ borderRadius: "8px" }}
  //                 ref={videoRef}
  //                 onLoadedMetadata={handleLoadedMetadata}
  //                 controls
  //               >
  //                 <source
  //                   src={previewUrl(selectedInvitationVideo)}
  //                   type="video/mp4"
  //                 />
  //               </video>
  //             </div>
  //           )}
  //         </> ):(
  //           <>
  //           {isPortrait ? (
  //             <div
  //               className="video-container2"
  //               style={{ transform: "rotate(90deg)" }}
  //             >
  //               <video
  //                 style={{
  //                   borderRadius: "8px",
  //                   transform: "rotate(-90deg)",
  //                 }}
  //                 className="padding-responsive-mobile-video"
  //                 ref={videoRef}
  //                 onLoadedMetadata={handleLoadedMetadata}
  //                 controls
  //               >
  //                 <source
  //                   style={{ borderRadius: "8px" }}
  //                   src={eventData?.invitation_video}
  //                   type="video/mp4"
  //                 />
  //               </video>
  //             </div>
  //           ) : (
  //             <div className="video-container2">
  //               <video
  //                 style={{ borderRadius: "8px" }}
  //                 ref={videoRef}
  //                 onLoadedMetadata={handleLoadedMetadata}
  //                 controls
  //               >
  //                 <source
  //                   src={eventData?.invitation_video}
  //                   type="video/mp4"
  //                 />
  //               </video>
  //             </div>
  //           )}
  //         </>
  //       )}
  //       {

  //       }
  //     </>
  //   );
  // };

  // const renderLiveVideoSection = () => {
  //   const videoId = extractYouTubeVideoId();
  //   const liveId = extractYouTubeLiveId();
  //   const hlsUrl = extractHlsUrl();

  //   if (videoId) {
  //     return (
  //       <div
  //         style={{
  //           backgroundImage:
  //             "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
  //         }}
  //         className="video-container"
  //       >
  //         <div className="youtube_recorded_block">
  //           <iframe
  //             height="inherit"
  //             width="inherit"
  //             className="youtube_recorded"
  //             src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
  //             frameBorder="0"
  //             styles={{ borderRadius: "8px" }}
  //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //             allowFullScreen
  //             title="YouTube Video"
  //           ></iframe>
  //         </div>
  //       </div>
  //     );
  //   } else if (liveId) {
  //     return (
  //       <div
  //         style={{
  //           backgroundImage:
  //             "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
  //         }}
  //         className="video-container"
  //       >
  //         <div className="youtube_recorded_block">
  //           <iframe
  //             styles={{ borderRadius: "8px" }}
  //             height="inherit"
  //             width="inherit"
  //             title="Live"
  //             className="youtube_recorded"
  //             src={`https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`}
  //             frameBorder="0"
  //             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  //             allowFullScreen
  //           ></iframe>
  //         </div>
  //       </div>
  //     );
  //   } else if (hlsUrl) {
  //     return (
  //       <div
  //         style={{
  //           backgroundImage:
  //             "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
  //         }}
  //         className="video-container"
  //       >
  //         <HLSPlayer preview={true} />
  //       </div>
  //     );
  //   } else if (
  //     eventData?.offline_video != null &&
  //     eventData?.offline_video !== ""
  //   ) {
  //     return (
  //       <div
  //         style={{
  //           backgroundImage:
  //             "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
  //         }}
  //         className="video-container"
  //       >
  //         {/* <video controls className={styles.offline_video_block}>
  //           <source src={eventData?.offline_video} type="video/mp4" />
  //         </video> */}
  //       </div>
  //     );
  //   }
  // };

  // const renderOfflineVideo = () => {
  //   return (
  //     <>
  //       {singleEventData?.offline_video !== null &&
  //         singleEventData?.offline_video !== "" && (
  //           <div
  //             style={{
  //               backgroundImage:
  //                 "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')",
  //             }}
  //             className="video-container"
  //           >
  //             <video
  //               style={{ width: "inherit !important" }}
  //               controls
  //               className={styles.offline_video_block}
  //             >
  //               <source src={singleEventData.offline_video} type="video/mp4" />
  //               Your browser does not support the video tag.
  //             </video>
  //           </div>
  //         )}
  //       {}
  //     </>
  //   );
  // };

  const renderGallerySection = () => {
    return (
      <div id="gallery" className={styles.galleryBlock}>
        {eventData &&
          Array.isArray(eventData.images) &&
          eventData.images.length > 0 && (
            <>
              <p className={styles.galleryHeading}>Our Wedding Gallery</p>
              <div style={{ width: "100%" }}>
                <FancyBoxGallery preview={true} />
              </div>
            </>
          )}
      </div>
    );
  };

  const renderFooterSection = (mobile) => {
    const footerStyle = mobile
      ? { borderTopLeftRadius: "30px", borderTopRightRadius: "30px" }
      : {};

    return (
      <div style={footerStyle}>
        <p className={styles.footerHeading}>Forever our love, Thank you</p>
        <p>Copyright 2024, Made with love by Infinitum Tech</p>
      </div>
    );
  };
  const renderOfflineVideoModificationAndViewingection = (hider) => {
    const handleLoadedMetadataOfflineVideo = () => {
      if (vidRef.current) {
        setOfflineVideoDimensions({
          width: vidRef.current.videoWidth,
          height: vidRef.current.videoHeight,
        });
      }
    };

    const isLandscape =
      offlineVideoDimensions.width > offlineVideoDimensions.height;

    return (
      <div>
        {selectedOfflineVideo === null ||
          eventData?.offline_video === null ||
          (singleEventData?.offline_video === null && (
            <div
              style={{ flexDirection: "column" }}
              className="btns-flex box-border-styling"
            >
              {!hider && <h4>Offline Video</h4>}
              {!hider && (
                <Button
                  component="label"
                  variant="contained"
                  style={{ fontSize: "10px" }}
                  startIcon={<CloudUploadIcon />}
                  className="btnsflexbtns"
                >
                  Offline Video
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange(setSelectedOfflineVideo)}
                  />
                </Button>
              )}

              {/* {renderLiveVideoSection()} */}
            </div>
          ))}

        {selectedOfflineVideo && selectedOfflineVideo !== null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundImage: `${
                hider &&
                `${
                  !isLandscape
                    ? "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1730096986/image_rotated_hvnqjc.jpg')"
                    : "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')"
                }`
              }`,
            }}
            className={`${
              hider ? "video-container" : "btns-flex box-border-styling"
            } `}
          >
            {!hider && <h4>Offline Video</h4>}
            <video
              ref={vidRef}
              src={videoSrc}
              onLoadedMetadata={handleLoadedMetadataOfflineVideo}
              controls
              style={{
                borderRadius: "8px",
                marginTop: "10px",
                width: isLandscape ? "80%" : "",
                height: isLandscape ? "100%" : "250px",
              }}
            />

            {!hider && (
              <div className="btns-flex">
                <Button
                  component="label"
                  style={{ fontSize: "10px" }}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Offline video
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChangeForOffline}
                  />
                </Button>
                <Button
                  component="label"
                  variant="contained"
                  color="error"
                  style={{ fontSize: "10px" }}
                  startIcon={<MdDeleteForever />}
                >
                  Offline Video
                  <VisuallyHiddenInput
                    onClick={handleFileDelete(setSelectedOfflineVideo)}
                  />
                </Button>
              </div>
            )}
          </div>
        ) : (
          eventData?.offline_video &&
          eventData?.offline_video != null && (
            <div
              className={`${hider && "video-container"} ${
                !hider && "btns-flex box-border-styling"
              } `}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundImage: `${
                  hider &&
                  `${
                    !isLandscape
                      ? "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1730096986/image_rotated_hvnqjc.jpg')"
                      : "url('https://res.cloudinary.com/dmhx6frzp/image/upload/v1728470953/image_2_jmzdab.jpg')"
                  }`
                }`,
              }}
            >
              {!hider && <h4>Offline Video</h4>}

              <video
                controls
                ref={vidRef}
                src={eventData?.offline_video}
                onLoadedMetadata={handleLoadedMetadataOfflineVideo}
                // src={`${eventData.offline_video}`}
                style={{
                  borderRadius: "8px",
                  marginTop: "10px",
                  width: isLandscape ? "100%" : "",
                  height: isLandscape ? "100%" : "250px",
                }}
              />
              {!hider && (
                <div className="btns-flex">
                  <Button
                    component="label"
                    variant="contained"
                    style={{ fontSize: "10px" }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Offline Video
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChangeForOffline}
                    />
                  </Button>
                  <Button
                    component="label"
                    variant="contained"
                    color="error"
                    style={{ fontSize: "10px" }}
                    startIcon={<MdDeleteForever />}
                  >
                    Offline Video
                    <VisuallyHiddenInput
                      onClick={handleFileDelete(setSelectedOfflineVideo)}
                    />
                  </Button>
                </div>
              )}
            </div>
          )
        )}
      </div>
    );
  };

  const uploadFileInChunks = async (file, apiUrl) => {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size, adjust as needed
    if (!file) return;

    setUploading(true); // Start uploading
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let chunkIndex = 0;
    const fileName = file.name;
    while (chunkIndex < totalChunks) {
      const chunkStart = chunkIndex * CHUNK_SIZE;
      const chunkEnd = Math.min(chunkStart + CHUNK_SIZE, file.size);
      const chunk = file.slice(chunkStart, chunkEnd);

      const formData = new FormData();
      formData.append("file", chunk); // Append the chunk of the file
      formData.append("chunkIndex", chunkIndex);
      formData.append("totalChunks", totalChunks);
      formData.append("fileName", fileName);
      formData.append("prlink", selectedRowId); // Assuming this is a necessary field

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            const percent = Math.round((chunkIndex / totalChunks) * 100);
            setProgress(percent);
          },
        });
        if (response.status === 200) {
          console.log(
            `Chunk ${fileName} ${chunkIndex + 1} uploaded successfully`
          );
        }
      } catch (error) {
        console.error("Error uploading chunk:", error);
        break; // Break if any chunk fails
      }

      chunkIndex++;
    }
    // Set uploading to false when the last chunk is uploaded
    if (chunkIndex >= totalChunks) {
      handleSubmitEdit();
      setUploading(false); // Mark as not uploading after the last chunk
    }
  };

  const dateFormatted = new Date(content?.venue_date);
  const day = dateFormatted.getDate();
  const month = dateFormatted.getMonth();
  const year = dateFormatted.getFullYear();
  const date = `${day}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${year}`;

  return (
    <div
      style={{
        width: "100%",
        padding: "10px 16px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {!showLoader ? (
        <>
          <div className="edit-section">
            <h1
              style={{
                textAlign: "center",
                fontFamily: "cursive",
                color: "slategrey",
              }}
            >
              Edit Event
            </h1>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <div>
                <div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      htmlFor="username"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    >
                      User Name
                    </label>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          borderColor: `${
                            content?.userName === "" ? "red" : "#ccc"
                          }`,
                        }}
                        className="para-styling"
                      >
                        {cleanHtml(content?.userName)}
                      </p>
                      {textEditor?.userName ? (
                        <button
                          onClick={() => {
                            if (content.userName === "") {
                              toast.error("Must Input Username");
                              return;
                            } else {
                              setTextEditor({ ...textEditor, userName: false });
                              setEventData({
                                ...eventData,
                                name: content.userName,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setTextEditor({ ...textEditor, userName: true });
                            }}
                            style={{
                              backgroundColor: "#4CAF50",
                            }}
                            className="btn-styling"
                          >
                            <FaEdit style={{ marginRight: "3px" }} />
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                    {textEditor?.userName && renderTextEditor("userName")}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="contact"
                    >
                      Contact Number
                    </label>
                    <div style={{ display: "flex" }}>
                      <PhoneInput
                        country={"in"}
                        disabled={true}
                        sx={{
                          borderColor: `${
                            content?.contact === "" ? "red" : "#ccc"
                          }`,
                        }}
                        value={eventData?.contact}
                      />
                      {/* <p
                    style={{
                      borderColor: `${
                        content?.contact === "" ? "red" : "#ccc"
                      }`,
                    }}
                    className="para-styling"
                  >
                    {(content?.contact)}
                  </p> */}
                      {textEditor?.contact ? (
                        <button
                          onClick={() => {
                            if (content.contact === "") {
                              toast.error("Must Input Contact Number");
                              return;
                            } else if (content.contact.length !== 12) {
                              toast.error("Contact Number Must be 10 digits");
                              return;
                            } else {
                              setTextEditor({ ...textEditor, contact: false });
                              setEventData({
                                ...eventData,
                                contact: content.contact,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, contact: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.contact && (
                      <PhoneInput
                        country={"in"}
                        value={content?.contact}
                        onChange={(value) =>
                          setContent({
                            ...content,
                            contact: value,
                          })
                        }
                      />
                      // {errorMessage.contact && (
                      //   <p style={{ color: "red" }}>{errorMessage.contact}</p>
                      // )}
                    )}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="event-name"
                    >
                      Event Name
                    </label>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          borderColor: `${
                            content?.eventName === "" ? "red" : "#ccc"
                          }`,
                        }}
                        className="para-styling"
                      >
                        {cleanHtml(content?.eventName)}
                      </p>
                      {textEditor?.eventName ? (
                        <button
                          onClick={() => {
                            if (content.eventName === "") {
                              toast.error("Must Input Event Name");
                              return;
                            } else {
                              setTextEditor({
                                ...textEditor,
                                eventName: false,
                              });
                              setEventData({
                                ...eventData,
                                title: content.eventName,
                              });
                              // setContent("");
                            }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, eventName: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.eventName && renderTextEditor("eventName")}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="event-type"
                    >
                      Select Event
                    </label>
                    <div>
                      <div style={{ display: "flex" }}>
                        <p
                          style={{
                            borderColor: `${
                              content?.template_desc === "" ? "red" : "#ccc"
                            }`,
                          }}
                          className="para-styling"
                        >
                          {cleanHtml(content?.template_desc)}
                        </p>
                        {textEditor?.template ? (
                          <button
                            onClick={() => {
                              if (content.template_desc === "") {
                                toast.error("Must Input Event Discription");
                                return;
                              } else {
                                setTextEditor({
                                  ...textEditor,
                                  template: false,
                                });
                                setEventData({
                                  ...eventData,
                                  template: content.template,
                                  template_desc: content.template_desc,
                                });
                              }
                            }}
                            style={{
                              backgroundColor: "#2196F3",
                            }}
                            className="btn-styling"
                          >
                            <MdSaveAs style={{ marginRight: "3px" }} />
                            Save
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setTextEditor({ ...textEditor, template: true });
                            }}
                            style={{
                              backgroundColor: "#4CAF50",
                            }}
                            className="btn-styling"
                          >
                            <FaEdit style={{ marginRight: "3px" }} />
                            Edit
                          </button>
                        )}
                      </div>

                      <select
                        disabled={!textEditor?.template}
                        id="event-type"
                        value={content?.template}
                        onChange={(e) => {
                          setOption(e.target.value);
                          setContent({
                            ...content,
                            template: e.target.value,
                          });
                        }}
                        className="select"
                      >
                        <option value="Birthday">Birthday</option>
                        <option value="Marriage">Marriage</option>
                        <option value="Saree Ceremony">Saree Ceremony</option>
                        <option value="Cultural Event">Cultural Event</option>
                        <option value="Family Reunion">Family Reunion</option>
                        <option value="Party">Party</option>
                        <option value="others">Others</option>
                      </select>
                      {textEditor?.template &&
                        renderTextEditor("template_desc")}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="venue"
                    >
                      Venue
                    </label>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          borderColor: `${
                            content?.venue === "" ? "red" : "#ccc"
                          }`,
                        }}
                        className="para-styling"
                      >
                        {cleanHtml(content?.venue)}
                      </p>
                      {textEditor?.venue ? (
                        <button
                          onClick={() => {
                            if (content.venue === "") {
                              toast.error("Must Input Event Address");
                            } else {
                              setTextEditor({ ...textEditor, venue: false });
                              setEventData({
                                ...eventData,
                                venue: content?.venue,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venue: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    <p
                      style={{
                        color: "red",
                        textAlign: "right",
                        fontSize: "10px",
                      }}
                    >
                      {stripHtmlTags(content?.venue).length >= 97 &&
                        `upto 100 chars only ${
                          stripHtmlTags(content?.venue).length
                        }`}
                    </p>
                    {textEditor?.venue && renderTextEditor("venue")}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="venue-date"
                    >
                      Venue Date
                    </label>

                    <div style={{ display: "flex" }}>
                      <p className="para-styling">{date}</p>

                      {textEditor?.venueDate ? (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueDate: false });
                            setEventData({
                              ...eventData,
                              venue_date: content.venue_date,
                            });
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueDate: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.venueDate && (
                      <TextField
                        margin="dense"
                        name="venue_date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={content?.venue_date}
                        inputProps={{
                          style: {
                            backgroundColor: "rgb(255, 255, 255)",
                          },
                        }}
                        onChange={(e) =>
                          setContent({ ...content, venue_date: e.target.value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="edit-data-input-style"
                      />
                    )}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="venue-time"
                    >
                      Venue Time
                    </label>
                    <div style={{ display: "flex" }}>
                      <p className="para-styling">{content?.venue_time}</p>

                      {textEditor?.venueTime ? (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueTime: false });
                            setEventData({
                              ...eventData,
                              venue_time: content.venue_time,
                            });
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueTime: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.venueTime && (
                      <TextField
                        margin="dense"
                        name="venue_time"
                        type="time"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            backgroundColor: "rgb(255, 255, 255)",
                          },
                        }}
                        value={content?.venue_time}
                        onChange={(e) =>
                          setContent({ ...content, venue_time: e.target.value })
                        }
                        className="edit-data-input-style"
                      />
                    )}
                  </div>
                </div>
              </div>

              {selectedBanner && selectedBanner != null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="box-border-styling"
                >
                  <h4>Banner Section</h4>
                  <img
                    src={previewUrl(selectedBanner)}
                    alt="Banner Preview"
                    style={{
                      width: "80%",
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                  />
                  <div className="btns-flex">
                    <Button
                      component="label"
                      variant="contained"
                      style={{ fontSize: "10px" }}
                      startIcon={<CloudUploadIcon />}
                    >
                      Banner
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedBanner)}
                      />
                    </Button>
                    <Button
                      component="label"
                      variant="contained"
                      color="error"
                      style={{ fontSize: "10px" }}
                      startIcon={<MdDeleteForever />}
                    >
                      Banner
                      <VisuallyHiddenInput
                        onClick={handleFileDelete(setSelectedBanner)}
                      />
                    </Button>
                  </div>
                </div>
              ) : (
                eventData?.banner &&
                eventData?.banner != null && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="box-border-styling"
                  >
                    <h4>Banner Section</h4>
                    <img
                      src={`${eventData?.banner}`}
                      alt="Banner"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        borderRadius: "8px",
                        marginTop: "10px",
                      }}
                    />
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px" }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Banner
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange(setSelectedBanner)}
                        />
                      </Button>
                      <Button
                        component="label"
                        variant="contained"
                        color="error"
                        style={{ fontSize: "10px" }}
                        startIcon={<MdDeleteForever />}
                      >
                        Banner
                        <VisuallyHiddenInput
                          onClick={handleFileDelete(setSelectedBanner)}
                        />
                      </Button>
                    </div>
                  </div>
                )
              )}
              {selectedBanner === null &&
                (eventData?.banner === "" || eventData?.banner === null) && (
                  <div
                    style={{ flexDirection: "column" }}
                    className="btns-flex box-border-styling"
                  >
                    <h4>Banner Section</h4>
                    <Button
                      component="label"
                      variant="contained"
                      sx={{ fontSize: "10px" }}
                      startIcon={<CloudUploadIcon />}
                      className="btnsflexbtns"
                    >
                      Banner
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedBanner)}
                      />
                    </Button>
                  </div>
                )}
              {selectedInvitationCard && selectedInvitationCard != null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="box-border-styling"
                >
                  <h4>Invitation Card</h4>
                  <img
                    src={previewUrl(selectedInvitationCard)}
                    alt="Invitation Card Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                  />
                  <div className="btns-flex">
                    <Button
                      component="label"
                      variant="contained"
                      style={{ fontSize: "10px" }}
                      startIcon={<CloudUploadIcon />}
                    >
                      Invitation card
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedInvitationCard)}
                      />
                    </Button>
                    <Button
                      component="label"
                      variant="contained"
                      color="error"
                      style={{ fontSize: "10px" }}
                      startIcon={<MdDeleteForever />}
                    >
                      Invitation_card
                      <VisuallyHiddenInput
                        onClick={handleFileDelete(setSelectedInvitationCard)}
                      />
                    </Button>
                  </div>
                </div>
              ) : (
                eventData?.invitation_card &&
                eventData?.invitation_card != null && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="box-border-styling"
                  >
                    <h4>Invitation Card</h4>
                    <img
                      src={`${eventData.invitation_card}`}
                      alt="Invitation Card"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        borderRadius: "8px",
                        marginTop: "10px",
                      }}
                    />
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px" }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Invitation card
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange(setSelectedInvitationCard)}
                        />
                      </Button>
                      <Button
                        component="label"
                        variant="contained"
                        color="error"
                        style={{ fontSize: "10px" }}
                        startIcon={<MdDeleteForever />}
                      >
                        Invitation card
                        <VisuallyHiddenInput
                          onClick={handleFileDelete(setSelectedInvitationCard)}
                        />
                      </Button>
                    </div>
                  </div>
                )
              )}
              {selectedInvitationCard === null &&
                (eventData?.invitation_card === "" ||
                  eventData.invitation_card === null) && (
                  <div
                    style={{ flexDirection: "column" }}
                    className="btns-flex box-border-styling"
                  >
                    <h4>Invitation Card</h4>
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      className="btnsflexbtns"
                      style={{ fontSize: "10px" }}
                    >
                      Invitation Card
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedInvitationCard)}
                      />
                    </Button>
                  </div>
                )}
              {selectedInvitationVideo && selectedInvitationVideo != null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="box-border-styling"
                >
                  <h4>Invitation Video</h4>
                  <video
                    controls
                    src={previewUrl(selectedInvitationVideo)}
                    style={{
                      maxWidth: "80%",
                      maxHeight: "300px",
                      borderRadius: "8px",
                      margin: "10px 0px",
                    }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      component="label"
                      variant="contained"
                      style={{ fontSize: "10px" }}
                      startIcon={<CloudUploadIcon />}
                    >
                      Invitation video
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedInvitationVideo)}
                      />
                    </Button>
                    <Button
                      component="label"
                      variant="contained"
                      color="error"
                      style={{ fontSize: "10px" }}
                      startIcon={<MdDeleteForever />}
                    >
                      Invitation Video
                      <VisuallyHiddenInput
                        onClick={handleFileDelete(setSelectedInvitationVideo)}
                      />
                    </Button>
                  </div>
                </div>
              ) : (
                eventData?.invitation_video &&
                eventData?.invitation_video != null && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="box-border-styling"
                  >
                    <h4>Invitation Video</h4>
                    <video
                      controls
                      src={`${eventData.invitation_video}`}
                      style={{
                        maxWidth: "80%",
                        maxHeight: "300px",
                        borderRadius: "8px",
                        marginTop: "10px",
                      }}
                    />
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px" }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Invitation video
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange(
                            setSelectedInvitationVideo
                          )}
                        />
                      </Button>
                      <Button
                        component="label"
                        variant="contained"
                        color="error"
                        style={{ fontSize: "10px" }}
                        startIcon={<MdDeleteForever />}
                      >
                        Invitation Video
                        <VisuallyHiddenInput
                          onClick={handleFileDelete(setSelectedInvitationVideo)}
                        />
                      </Button>
                    </div>
                  </div>
                )
              )}
              {selectedInvitationVideo === null &&
                (eventData?.invitation_video === "" ||
                  eventData.invitation_video === null) && (
                  <div
                    style={{ flexDirection: "column" }}
                    className="btns-flex box-border-styling"
                  >
                    <h4>Invitation Video</h4>
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      className="btnsflexbtns"
                      style={{ fontSize: "10px" }}
                    >
                      Invitation video
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedInvitationVideo)}
                      />
                    </Button>
                  </div>
                )}

              {singleEventData?.offline_video ||
              eventData?.offline_video ||
              selectedOfflineVideo
                ? renderOfflineVideoModificationAndViewingection()
                : renderLiveVideoComponent()}
              {selectedOfflineVideo === null &&
                eventData?.offline_video === null &&
                eventData?.live_vidlink === "" && (
                  <div
                    style={{ flexDirection: "column" }}
                    className="btns-flex box-border-styling"
                  >
                    <h4 style={{ textAlign: "center" }}>Offline Video</h4>

                    <div className="" style={{ marginTop: "10px" }}>
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px" }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Offline Video
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChangeForOffline}
                          // onChange={handleFileChange(setSelectedOfflineVideo)}
                        />
                      </Button>
                    </div>
                  </div>
                )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="box-border-styling"
              >
                <h4>Gallery</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginTop: "10px",
                  }}
                >
                  {eventData?.images?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        width: "100px", // set a fixed width for all images
                        height: "120px", // set a fixed height for all images
                        display: "flex", // flex container for alignment
                        justifyContent: "center", // center horizontally
                        alignItems: "center", // center vertically
                      }}
                    >
                      <img
                        src={`${item.image}`}
                        alt={`Event ${index + 1}`}
                        style={{
                          width: "90px", // takes the full width of the container
                          height: "70px", // takes the full height of the container
                          objectFit: "cover", // ensures the image covers the area while maintaining aspect ratio
                          borderRadius: "8px", // rounded corners
                        }}
                      />
                      <button
                        onClick={() => deleteImage(item.id)}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "-5px",
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                  {selectedImages.map((image, index) => (
                    <img
                      key={index}
                      src={previewUrl(image)}
                      alt={`Selected ${index + 1}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ))}
                </div>
                {/* {loading && <CircularProgress />} */}
                {eventData &&
                  Array.isArray(eventData.images) &&
                  eventData.images.length > 0 && (
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px", marginTop: "10px" }}
                        startIcon={<CloudUploadIcon />}
                        // disabled={loading}
                        className="btnsflexbtns"
                      >
                        Images
                        <VisuallyHiddenInput
                          type="file"
                          multiple
                          onChange={handleImagesChange}
                        />
                      </Button>
                    </div>
                  )}
                {Array.isArray(selectedImages) &&
                  selectedImages.length === 0 &&
                  Array.isArray(eventData.images) &&
                  eventData.images.length === 0 && (
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px", marginTop: "10px" }}
                        startIcon={<CloudUploadIcon />}
                        className="btnsflexbtns"
                      >
                        Images
                        <VisuallyHiddenInput
                          type="file"
                          multiple
                          onChange={handleImagesChange}
                        />
                      </Button>
                    </div>
                  )}
              </div>
              <div>
                <div>
                  {singleChannelData &&
                    singleChannelData?.rtmp_id !== "Removed" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        className="box-border-styling"
                      >
                        <div className="stream-sontainer">
                          <h2 className="heading">Select Stream Type</h2>
                          <div className="radio-group">
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="RTMP"
                                checked={selectedOption === "RTMP"}
                                onChange={handleOptionChange}
                                className="radio-input"
                              />
                              RTMP
                            </label>
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="RTMPS"
                                checked={selectedOption === "RTMPS"}
                                onChange={handleOptionChange}
                                className="radio-input"
                              />
                              RTMPS
                            </label>
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="SRT"
                                checked={selectedOption === "SRT"}
                                onChange={handleOptionChange}
                                className="radio-input"
                              />
                              SRT
                            </label>
                          </div>

                          <div className="input-container">
                            {selectedOption === "RTMP" && (
                              <div className="input-group">
                                <input
                                  type="text"
                                  value={singleChannelData?.rtmp_id}
                                  readOnly
                                  className="input-field"
                                />
                                <input
                                  type="text"
                                  value={singleChannelData?.Stream_access_key}
                                  readOnly
                                  className="input-field"
                                />
                              </div>
                            )}
                            {selectedOption === "RTMPS" && (
                              <div className="input-group">
                                <input
                                  type="text"
                                  value={singleChannelData?.rtmps_id}
                                  readOnly
                                  className="input-field"
                                />
                                <input
                                  type="text"
                                  value={singleChannelData?.Stream_access_key}
                                  readOnly
                                  className="input-field"
                                />
                              </div>
                            )}
                            {selectedOption === "SRT" && (
                              <div className="input-group">
                                <input
                                  type="text"
                                  value={singleChannelData?.srt_id}
                                  readOnly
                                  className="input-field"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div>{renderEditPassword()}</div>
            </div>

            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleCloseEdit}
                variant="outlined"
                color="primary"
                className="edit-btn-action-style"
              >
                Cancel
              </Button>
              {editingValidator() ? (
                <Button
                  onClick={handlerEditEvent}
                  variant="contained"
                  color="primary"
                  className="edit-btn-action-style"
                >
                  Save
                </Button>
              ) : (
                <Button
                  onClick={handlerEditEvent}
                  variant="contained"
                  color="primary"
                  className="edit-btn-action-style"
                >
                  Save
                </Button>
              )}
            </DialogActions>
            {renderConfirmSection()}
            {renderCancelSection()}
          </div>
          <div className="preview-section">
            <h1
              style={{
                textAlign: "center",
                fontFamily: "cursive",
                color: "slategrey",
                backgroundColor: "#f0f8ff",
              }}
            >
              Preview section
            </h1>
            {editingValidator() && (
              <div
              // className={`${styles.templateTransitionGlobal} ${
              //   componentsVisible ? styles.fadeIn : styles.fadeOut
              // }`}
              >
                {renderNavigation()}
                <div className="template-global">
                  {renderBannerImage()}
                  {/* {renderVenueSection()} */}
                  {renderInvitationSection()}
                  {singleEventData?.offline_video ||
                  eventData?.offline_video ||
                  selectedOfflineVideo
                    ? renderOfflineVideoModificationAndViewingection("hide")
                    : renderLiveVideoComponent("hide")}
                  {renderGallerySection()}
                  {renderFooterSection()}
                </div>
              </div>
            )}
          </div>

          <div className="slider-btn">
            <div className={`sidebar ${isOpen ? "open" : ""}`}>
              <button onClick={toggleSidebar} className="toggle-button">
                <span className="toggle-text">
                  {isOpen ? "Close" : "Preveiw"}
                </span>
              </button>
              <div
                className={`sidebar-content ${isOpen ? "visible" : "hidden"}`}
              >
                <div>
                  {editingValidator() && (
                    <div
                    // className={`${styles.templateTransitionGlobal} ${
                    //   componentsVisible ? styles.fadeIn : styles.fadeOut
                    // }`}
                    >
                      {renderNavigation(true)}
                      <div className="template-global">
                        {renderBannerImage()}
                        {/* {renderVenueSection()} */}
                        {renderInvitationSection(true)}
                        {singleEventData?.offline_video ||
                        eventData?.offline_video ||
                        selectedOfflineVideo
                          ? renderOfflineVideoModificationAndViewingection(
                              "hide"
                            )
                          : renderLiveVideoComponent("hide")}
                        {renderGallerySection()}
                        {renderFooterSection(true)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : progress ? (
        <UploadProgressPopup progress={progress} isUploading={uploading} />
      ) : (
        <EditLoadingView />
      )}
    </div>
  );
};

export default EditEvent;
