import {OrbitProgress } from "react-loading-indicators";

import "./index.css";

const LoadingView = () => (
  <>
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="honeycomb"
    >
      <OrbitProgress
        variant="spokes"
        dense
        color="#e94e77"
        speedPlus="4"
        size="medium"
        text=""
        textColor=""
      />
    </div>
  </>
);

export default LoadingView;
