import React from "react";
import { motion } from "framer-motion";
import { LogIn, UserPlus, Menu } from "lucide-react";
import page_logo from "../../assets/images/landing_logo.png";
import "./homeHeader.css"; // Import the custom CSS file
import { useNavigate } from "react-router-dom";

const NavLink = ({ href, children }) => (
  <motion.a href={href} className="nav-link" whileHover={{ scale: 1.05 }}>
    {children}
  </motion.a>
);

const MobileNavLink = ({ href, children }) => (
  <motion.a
    href={href}
    className="menu-item"
    whileHover={{ backgroundColor: "#F3F4F6" }}
  >
    {children}
  </motion.a>
);

const HomeHeader = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <motion.nav initial={{ y: -100 }} animate={{ y: 0 }} className="navbar">
      <div className="container">
        <div className="header-content">
          <motion.div
            className="logo"
            whileHover={{ scale: 1.05 }}
            onClick={() => navigate("/")}
          >
            <img
              style={{ width: "150px", marginTop: "6px" }}
              src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
              alt="nav-logo"
            />
          </motion.div>
          <div className="nav-links-buttons" style={{}}>
            <div className="nav-links">
              <NavLink href="#home">Home</NavLink>
              <NavLink href="#services">Services</NavLink>
              <NavLink href="#features">Features</NavLink>
              {/* <NavLink href="#pricing">Pricing</NavLink> */}
              <NavLink href="#support">Support</NavLink>
            </div>

            <div
              className="nav-buttons"
              style={{ display: "flex", gap: "8px" }}
            >
              <motion.button
                onClick={() => {
                  navigate("/login");
                }}
                whileHover={{ scale: 1.05 }}
                className="signin"
              >
                <LogIn size={20} />
                Sign In
              </motion.button>

              <motion.button
                onClick={() => {
                  navigate("/signup");
                }}
                whileHover={{ scale: 1.05 }}
                className="signup"
              >
                <UserPlus size={20} />
                Sign Up
              </motion.button>
            </div>
          </div>

          <div className="menu-icon">
            <button onClick={() => setIsOpen(!isOpen)}>
              <Menu size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <motion.div
        initial={false}
        animate={{ height: isOpen ? "auto" : 0 }}
        className={`mobile-menu ${isOpen ? "open" : ""}`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <MobileNavLink href="#home">Home</MobileNavLink>
          <MobileNavLink href="#services">Services</MobileNavLink>
          <MobileNavLink href="#features">Features</MobileNavLink>
          <MobileNavLink href="#support">Support</MobileNavLink>
          <MobileNavLink href="/login">Sign In</MobileNavLink>
          <MobileNavLink href="/signup">Sign Up</MobileNavLink>
        </div>
      </motion.div>
    </motion.nav>
  );
};

export default HomeHeader;
