import React, { useState, useEffect, useRef } from "react";
// import { HtmlValidate } from "html-validate";
import DOMPurify from "dompurify";
import { CopyAll as CopyIcon } from "@mui/icons-material";
import { Share as ShareIcon } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import { FaSort } from "react-icons/fa";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppDataContext } from "hooks/useappdatacontext";
import { FaEye } from "react-icons/fa";
import { routeNames } from "constants";
import BackDropLoader from "../backdropLoader";
import videojs from "video.js";
import "video.js/dist/video-js.css";
// import Hls from "hls.js";
import "./index.css";
import styles from "./styles.module.css";

const fonts = [
  "Dancing Script",
  "Great Vibes",
  "Sacramento",
  "Lobster",
  "Alex Brush",
  "Pacifico",
  "Allura",
  "Satisfy",
  "Pinyon Script",
  "Cookie",
  "Rochester",
  "Rosarivo",
  "Fredericka the Great",
  "Cedarville Cursive",
  "Clicker Script",
  "French Script MT",
  "Handlee",
  "Berkshire Swash",
  "Playball",
  "Niconne",
  "Zeyada",
  "Alfa Slab One",
  "Abril Fatface",
  "Parisienne",
  "Merienda",
  "Italiana",
  "Sirin Stencil",
  "Allerta Stencil",
  "Titan One",
  "Gloock",
  "Belgrano",
  "Kalam",
  "Sofia",
  "Marck Script",
  "Vampiro One",
  "Srisakdi",
  "Pattaya",
  "Julius Sans One",
  "Righteous",
];

const CrudTable = ({ searchQuery }) => {
  //snackbar copy text
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [anchorEl, setAnchorEl] = useState(null);

  //Text Editor

  const [popCanelTheEditOrConfirm, setpopCanelTheEditOrConfirm] =
    useState(false);

  const [textEditor, setTextEditor] = useState({
    userName: false,
    contact: false,
    eventName: false,
    template: false,
    venue: false,
    venueDate: false,
    venueTime: false,
  });

  const [option, setOption] = useState("");

  const navigate = useNavigate();

  // Contexts
  const {
    eventData,
    setEventData,
    getAllEvents,
    allEventsData,
    openDelete,
    setOpenDelete,
    deleteEventData,
    updateEventData,
    singleEventData,
    updateCustomHlsApi,
    showLoader,
    setShowLoader,
  } = useAppDataContext();

  const [content, setContent] = useState({
    userName: "",
    contact: "",
    eventName: "",
    template: "",
    venue: "",
    venue_date: "",
    venue_time: "",
    templateDiscripton: "",
    banner: null,
  });

  // States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openEdit, setOpenEdit] = useState(false);
  const [popCanelToEdit, setPopUpCancelToEdit] = useState(false);

  const [selectedRowId, setSelectedRowId] = useState(null);

  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedInvitationCard, setSelectedInvitationCard] = useState(null);
  const [selectedInvitationVideo, setSelectedInvitationVideo] = useState(null);
  const [selectedOfflineVideo, setSelectedOfflineVideo] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const [selectedOption, setSelectedOption] = useState("RTMPS");

  // dropdownCheck states
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPasswordOption, setSelectedPasswordoption] = useState("");
  const [validPassword, setValidPAssword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const [fetchedPassword, setFetchedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  //hls States
  const [hlsLiveUrl, setHlsLiveUrl] = useState("");
  const [error, setError] = useState("");

  //hls References
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const [minimumPassword, setMinimumPassword] = useState("");
  const [loadingView, setLoadingView] = useState(true);
  const [passwordVisibilty, setPasswordVisibility] = useState(true);

  const loggedInUser = JSON.parse(localStorage.getItem("userData"));
  const [linkIdentifire, setLinkIdentifire] = useState("");

  const [order, setOrder] = useState("ascending");

  const [fontStyle, setFontStyle] = useState(fonts[0]);
  //useEffect to link list of google fonts to head element

  useEffect(() => {
    fonts.forEach((font) => {
      const link = document.createElement("link");
      link.href = `https://fonts.googleapis.com/css2?family=${font.replace(
        /\s+/g,
        "+"
      )}:wght@400;700&display=swap`;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    });
  }, []);

  // hls player options
  const playerOptions = {
    autoplay: true,
    controls: true,
    fluid: true,
    preload: "auto",
    sources: [
      {
        src: hlsLiveUrl || "",
        type: "application/x-mpegURL",
      },
    ],
  };

  useEffect(() => {
    setHlsLiveUrl("");
    if (singleEventData?.live_vidlink) {
      setHlsLiveUrl("");
      const hlsLive = extractHlsUrl(singleEventData.live_vidlink);
      if (hlsLive) {
        setHlsLiveUrl(hlsLive);
      }
    }
  }, [singleEventData?.live_vidlink]);

  const initializePlayer = () => {
    if (playerRef.current) {
      playerRef.current = null;
    }
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, playerOptions, () => {
        console.log("Player is ready");
        setLoadingView(false);
      });

      let retryCount = 0; // Initialize retry counter

      // Add error handling
      playerRef.current.on("error", (error) => {
        console.error("Video.js Error:", error);

        if (error.code === 3) {
          // MEDIA_ERR_DECODE
          if (retryCount < 3) {
            retryCount++; // Increment retry counter
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
            }, 5000);
          } else {
            setError("Event stream is not live.");
          }
        } else {
          if (retryCount < 3) {
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
              retryCount++; // Increment retry counter
            }, 3000);
          } else {
            setError("Cant Access Stream!! Please Reload the Page");
            // window.location.reload()
          }
        }
      });
      setError("");
    }
  };
  useEffect(() => {
    if (openEdit) {
      // Dispose and reinitialize player when hlsLiveUrl or modal changes
      setTimeout(initializePlayer, 1000);
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [hlsLiveUrl]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //function to remove html tags and css from element
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  //fuction for name wise filtering.
  const displayData = allEventsData
    .filter(
      (event) =>
        stripHtmlTags(event.title.toLowerCase()).includes(
          searchQuery.toLowerCase()
        ) ||
        event.contact.includes(searchQuery) ||
        stripHtmlTags(event.name.toLowerCase()).includes(
          searchQuery.toLowerCase()
        )
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // const displayData = allEventsData
  //   .sort((a, b) => new Date(a.venue_date) - new Date(b.venue_date))
  //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleClickOpenDelete = (rowId) => {
    setSelectedRowId(rowId);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedRowId(null);
  };

  const deleteEvent = async (id) => {
    setShowLoader(true);
    await deleteEventData(id);
    setOpenDelete(false);
    getAllEvents();
    setShowLoader(false);
  };

  const editingValidator = () =>
    singleEventData.name !== eventData.name ||
    singleEventData.contact !== eventData.contact ||
    singleEventData.title !== eventData.title ||
    singleEventData.template !== eventData.template ||
    singleEventData.venue !== eventData.venue ||
    singleEventData.venue_time !== eventData.venue_time ||
    singleEventData.venue_date !== eventData.venue_date ||
    eventData.is_secure !== "" ||
    selectedBanner ||
    eventData.banner !== singleEventData.banner ||
    selectedInvitationCard ||
    eventData.invitation_card !== singleEventData.invitation_card ||
    selectedInvitationVideo ||
    eventData.invitation_video !== singleEventData.invitation_video ||
    selectedOfflineVideo ||
    eventData.offline_video !== singleEventData.offline_video;

  // Function to close edit modal
  useEffect(() => {
    if (eventData?.is_secure) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  }, [eventData?.is_secure]);

  const validatePassword = (value) => {
    if (value.length > 0 && value.length <= 8) {
      setMinimumPassword("It's a Valid Password");
    } else if (value.length === 0) {
      setMinimumPassword("");
    } else {
      setPasswordError("Password must be at least 8 characters long.");
    }
  };

  const resetForm = () => {
    setEventData((prev) => ({
      ...prev,
      password: "",
    }));
    setPasswordError("");
    setApiError("");
    setApiSuccess("");
    setFetchedPassword("");
  };

  // const handleGetChannelData = async (id) => {
  //   await getChannelData(id);
  // };

  // const handleImagesChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedImages((prevImages) => [...prevImages, ...files]);
  // };

  // const extractHlsUrl = (url) => {
  //   const regExp = /https?:\/\/[^\s]*\.m3u8/;
  //   const match = url?.match(regExp);
  //   return match ? match[0] : null;
  // };

  // const extractHlsUrl = (url) => {
  //   const regExp = /https?:\/\/[^\s]+\.m3u8/;
  //   const match = url?.match(regExp);
  //   return match ? match[0] : null;
  // };

  const isMobile = useMediaQuery("(max-width:800px)"); // Determine if screen width is less than 800px
  const extractHlsUrl = (url) => {
    const regExp = /(https?:\/\/[^\s]+\.m3u8)/;
    const match = url?.match(regExp);
    return match ? match[0] : null;
  };

  useEffect(() => {
    const getAllEventsData = async () => {
      setShowLoader(true);
      await getAllEvents();
      setShowLoader(false);
    };
    getAllEventsData();
  }, []);

  useEffect(() => {
    setContent({
      userName: singleEventData?.name,
      contact: singleEventData?.contact,
      eventName: singleEventData?.title,
      template: singleEventData?.template,
      venue: singleEventData?.venue,
      venue_date: singleEventData?.venue_date,
      venue_time: singleEventData?.venue_time,
      templateDiscripton: singleEventData.templateDescription || "Marriage",
      banner: singleEventData.banner,
    });
  }, [singleEventData]);
  useEffect(() => {
    setPopUpCancelToEdit(false);
    setEventData({
      name: singleEventData?.name,
      contact: singleEventData?.contact || "",
      password: singleEventData?.password || "",
      title: singleEventData?.title || "",
      venue: singleEventData?.venue || "",
      venue_date: singleEventData?.venue_date || "",
      venue_time: singleEventData?.venue_time || "",
      live_vidlink: singleEventData?.live_vidlink || "",
      banner: singleEventData?.banner,
      invitation_card: singleEventData?.invitation_card,
      invitation_video: singleEventData?.invitation_video,
      offline_video: singleEventData?.offline_video,
      images: singleEventData?.images || [],
      is_secure: singleEventData?.is_secure || "",
      template: singleEventData?.template || "",
      templateDescription: "",
    });
  }, [singleEventData]);

  const renderTableSection = () => {
    return (
      <React.Fragment>
        {allEventsData.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "auto",
            }}
          >
            <Typography variant="h6">No events created yet!</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(routeNames.event)}
            >
              Create Event
            </Button>
          </div>
        ) : (
          <>
            {renderTableTitleSection()}
            {renderTableContainerSection()}
            {renderTablePaginationSection()}
          </>
        )}
      </React.Fragment>
    );
  };

  const renderTableTitleSection = () => {
    return (
      <div className={styles.tablesec}>
        <Typography
          variant="h5"
          component="div"
          className={styles.createEventTitleStyle}
        >
          {filter === "today"
            ? "Today "
            : filter === "tomorrow"
            ? "Tomorrow "
            : "All "}
          Events
        </Typography>

        {/* Right-aligned Filter section */}
        <div className={styles.rightSection}>
          <button
            onClick={() => handleDateFilterClick("all")}
            className={styles.filterbtn}
            style={{ backgroundColor: filter === "all" ? "green" : "gray" }}
          >
            All
          </button>
          <button
            onClick={() => handleDateFilterClick("today")}
            className={styles.filterbtn}
            style={{ backgroundColor: filter === "today" ? "green" : "gray" }}
          >
            Today
          </button>
          <button
            onClick={() => handleDateFilterClick("tomorrow")}
            className={styles.filterbtn}
            style={{
              backgroundColor: filter === "tomorrow" ? "green" : "gray",
            }}
          >
            Tomorrow
          </button>
        </div>
      </div>
    );
  };

  const [filter, setFilter] = useState("all");

  // Date Filtering Function
  const filterEventsByDate = (data) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (filter === "today") {
      return data.filter(
        (event) =>
          new Date(event.venue_date).setHours(0, 0, 0, 0) === today.getTime()
      );
    } else if (filter === "tomorrow") {
      return data.filter(
        (event) =>
          new Date(event.venue_date).setHours(0, 0, 0, 0) === tomorrow.getTime()
      );
    }
    return data; // No filter, show all
  };

  // Toggle Filter Function
  const handleDateFilterClick = (selectedFilter) => {
    setFilter(selectedFilter); // Set the filter directly based on the button clicked
  };

  const stripedTitle = (check) => {
    const htmlString = check;
    const sanitizedHtml = DOMPurify.sanitize(htmlString);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = sanitizedHtml;
    const plainText = tempDiv.innerText || tempDiv.textContent;
    return plainText;
  };

  // Function to handle copying the text to clipboard
  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_MAIN_APP}/${link}`)
      .then(() => {
        setLinkIdentifire(link);
        setSnackbarMessage("Copied!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      })
      .catch(() => {
        setLinkIdentifire(link);
        setSnackbarMessage("Failed!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  // Function to trigger sharing
  const handleShare = (link) => {
    if (navigator.share) {
      // If Web Share API is available (typically works on mobile devices)
      navigator
        .share({
          url: `/${link}`,
        })
        .then(() => {
          setSnackbarMessage("Sharing...!");
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Failed to share content.");
          setOpenSnackbar(true);
        });
    } else {
      // Fallback for desktop users (or unsupported browsers)
      setSnackbarMessage(
        "Sharing is not supported on this platform. Copy this link instead!"
      );
      setOpenSnackbar(true);
      // Optionally, you can also offer a fallback mechanism like copying the URL:
      // navigator.clipboard.writeText(window.location.href);
    }
  };

  const funtionToSortTheEventsData = (data) => {
    data.sort((a, b) => {
      const dateA = new Date(a.venue_date);
      const dateB = new Date(b.venue_date);

      if (order === "ascending") {
        return dateA - dateB;
      } else if (order === "descending") {
        return dateB - dateA;
      } else {
        return data;
      }
    });
    return data;
  };

  let filteredData;
  filteredData = funtionToSortTheEventsData(displayData);
  filteredData = filterEventsByDate(displayData);

  const renderTableContainerSection = () => {
    return (
      <TableContainer
        sx={{
          maxHeight: "auto",
          width: "100%",

          "@media (max-width: 300px)": {
            padding: "0 4px",
          },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#30b2ef" }}>
              {!isMobile && (
                <TableCell
                  sx={{
                    fontSize: "14px",
                    color: "#fff",
                    maxWidth: "150px",
                  }}
                >
                  {loggedInUser && loggedInUser.email === "admin@user.com"
                    ? "User Details"
                    : "Event Details"}
                </TableCell>
              )}

              <TableCell
                sx={{
                  fontSize: "14px",
                  color: "#fff",
                  maxWidth: "100px",
                }}
              >
                Event
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "14px",
                  color: "#fff",
                  maxWidth: "50px",
                }}
              >
                <button
                  style={{
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                    background: "transparent",
                    border: "none",
                    color: "white",
                    fontSize: "1.1rem",
                  }}
                  onClick={() => {
                    if (order === "ascending") {
                      setOrder("descending");
                    } else if ("descending") {
                      setOrder("ascending");
                    }
                  }}
                >
                  Date
                  <FaSort />
                </button>
              </TableCell>
              {!isMobile && (
                <TableCell
                  sx={{
                    fontSize: "14px",
                    color: "#fff",
                    minWidth: "50px",
                  }}
                >
                  Event Time
                </TableCell>
              )}

              <TableCell
                sx={{
                  fontSize: "14px",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredData &&
              filteredData.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      backgroundColor: row.id % 2 === 0 ? "#f5f5f5" : "white",
                      "&:hover": { backgroundColor: "#e0f7fa" },
                    }}
                  >
                    {!isMobile && (
                      <TableCell sx={{ maxWidth: "150px", overflow: "auto" }}>
                        {loggedInUser && (
                          <>
                            {loggedInUser.email === "admin@user.com" ? (
                              <>
                                <p>{stripHtmlTags(row.name)}</p>
                                <p>{row.contact}</p>
                                <p>{row.email}</p>
                              </>
                            ) : (
                              <>
                                <p>{stripHtmlTags(row.name)}</p>
                                <p>{row.contact}</p>
                              </>
                            )}
                          </>
                        )}

                        <div style={{ display: "flex" }}>
                          <button
                            style={{
                              transition: "background-color 0.3s ease",
                              border: "none",
                              padding: "2px 10px",
                              color: "black",
                              backgroundColor: "#ebd8a0",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleShare(row.prlink);
                            }}
                          >
                            <ShareIcon />
                          </button>
                          <div style={{ position: "relative" }}>
                            <button
                              ref={(button) => setAnchorEl(button)} // Set reference to button
                              style={{
                                marginLeft: "4px",
                                transition: "background-color 0.3s ease",
                                border: "none",
                                padding: "2px 10px",
                                color: "white",
                                backgroundColor: "#4caf50",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleCopy(row.prlink)} // You can replace 'row.prlink' with actual data
                            >
                              <CopyIcon />
                            </button>
                            {linkIdentifire === row.prlink && (
                              <Snackbar
                                open={openSnackbar}
                                autoHideDuration={500}
                                onClose={() => setOpenSnackbar(false)}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "100%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <Alert
                                  onClose={() => setOpenSnackbar(false)}
                                  severity={snackbarSeverity}
                                  sx={{
                                    width: "100%",
                                    padding: "1px 4px",
                                    margin: "0px 0px 18px 28px",
                                  }}
                                >
                                  {snackbarMessage}
                                </Alert>
                              </Snackbar>
                            )}
                          </div>
                        </div>
                      </TableCell>
                    )}

                    <TableCell sx={{ maxWidth: "50px", overflow: "auto" }}>
                      {stripedTitle(row.title)}
                    </TableCell>

                    <TableCell sx={{ minWidth: "150px" }}>
                      {new Date(row.venue_date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </TableCell>

                    {!isMobile && (
                      <TableCell sx={{ minWidth: "50px" }}>
                        {new Date(
                          `1970-01-01T${row.venue_time}`
                        ).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        height: "125px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "5px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        color="info"
                        startIcon={<Edit />}
                        sx={{ fontSize: "12px" }}
                        onClick={() => {
                          navigate(`${routeNames.editEvent}/${row.prlink}`);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => {
                          handleClickOpenDelete(row.id);
                        }}
                        sx={{ fontSize: "12px" }}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        startIcon={<FaEye />}
                        onClick={() => {
                          navigate(`/${row.prlink}`);
                        }}
                        sx={{ fontSize: "12px" }}
                      >
                        Page
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTablePaginationSection = () => {
    return (
      <TablePagination
        component="div"
        count={allEventsData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // rowsPerPageOptions={[10, 20, 30]}
      />
    );
  };

  const renderDeleteEventSection = () => {
    return (
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle className={styles.confirmPromptStyle}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.confirmPromptStyle}>
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            className={styles.deleteActionBtnStyle}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteEvent(selectedRowId)}
            color="error"
            className={styles.deleteActionBtnStyle}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // //Text Editor
  // // name: "",
  // // nameFontFamily: "",
  // // nameFontSize: "",
  // // nameColor: "",

  // const renderFontStyleSelector = (filed) => (
  //   <select
  //     id="fontFamily"
  //     onChange={(e) => setEventData({ ...eventData, [filed]: e.target.value })}
  //     style={{
  //       fontFamily: eventData?.fontFamily,
  //       padding: "6px 3px",
  //       border: "1px solid #ccc",
  //       borderRadius: "4px",
  //       marginRight: "10px",
  //       fontSize: "10px",
  //       backgroundColor: "#fff",
  //       cursor: "pointer",
  //       boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
  //     }}
  //   >
  //     <option style={{ fontFamily: "normal" }} value="" disabled selected>
  //       Select Font
  //     </option>
  //     {fonts.map((font) => (
  //       <option style={{ fontFamily: font }} key={font} value={font}>
  //         {font}
  //       </option>
  //     ))}
  //   </select>

  //   // <Box sx={{ style: { width: "25%" } }}>
  //   //   <FormControl>
  //   //     <InputLabel id={`${typeInput}`}>Font</InputLabel>
  //   //     <Select
  //   //       focused
  //   //       labelId={`${typeInput}`}
  //   //       id="font-style"
  //   //       variant="outlined"
  //   //       margin="normal"
  //   //       value={fontStyle}
  //   //       label="Font"
  //   //       name={`${typeInput}`}
  //   //       style={{ fontFamily: fontStyle }}
  //   //       // onChange={(e) => {
  //   //       //   setFontStyle(e.target.value);
  //   //       // }}
  //   //     >
  //   //       {fonts.map((font) => (
  //   //         <MenuItem
  //   //           sx={{ fontFamily: `${font} !important` }}
  //   //           key={font}
  //   //           value={font}
  //   //         >
  //   //           {font}
  //   //         </MenuItem>
  //   //       ))}
  //   //     </Select>
  //   //   </FormControl>
  //   // </Box>
  // );
  // const renderFontSize = (filed) => (
  //   <select
  //     id="fontSize"
  //     onChange={(e) => setEventData({ ...eventData, [filed]: e.target.value })}
  //     style={{
  //       padding: "6px",
  //       border: "1px solid #ccc",
  //       borderRadius: "4px",
  //       marginRight: "10px",
  //       fontSize: "10px",
  //       backgroundColor: "#fff",
  //       cursor: "pointer",
  //       boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
  //     }}
  //   >
  //     <option value="" disabled selected>
  //       Select Font Size
  //     </option>
  //     <option value="6px">6</option>
  //     <option value="10px">10</option>
  //     <option value="14px">14</option>
  //     <option value="18px">18</option>
  //     <option value="22px">22</option>
  //     <option value="26px">26</option>
  //     <option value="30px">30</option>
  //     <option value="34px">34</option>
  //     <option value="38px">38</option>
  //   </select>
  // );

  // const renderFontColor = (filed) => (
  //   <>
  //     <input
  //       type="color"
  //       onChange={(e) =>
  //         setEventData({ ...eventData, [filed]: e.target.value })
  //       }
  //       style={{
  //         border: "none",
  //         width: "40px",
  //         height: "40px",
  //         cursor: "pointer",
  //         borderRadius: "4px",
  //         boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
  //       }}
  //     />
  //   </>
  // );

  // const fsList = [
  //   "normal",
  //   "bold",
  //   "lighter",
  //   "bolder",
  //   100,
  //   200,
  //   300,
  //   400,
  //   500,
  //   600,
  //   700,
  //   800,
  //   900,
  // ];

  // const renderFontWeight = (filed) => (
  //   <select
  //     onChange={(e) => setEventData({ ...eventData, [filed]: e.target.value })}
  //     style={{
  //       padding: "10px",
  //       border: "1px solid #ccc",
  //       borderRadius: "4px",
  //       marginRight: "10px",
  //       fontSize: "10px",
  //       backgroundColor: "#fff",
  //       cursor: "pointer",
  //       boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
  //     }}
  //   >
  //     <option value="" disabled selected>
  //       Select Font Weight
  //     </option>
  //     {fsList.map((each) => (
  //       <option value={each} key={each}>
  //         {each}
  //       </option>
  //     ))}
  //   </select>
  // );

  //date htmlchecker

  return (
    <Paper sx={{ width: "100%" }}>
      {renderTableSection()}
      {renderDeleteEventSection()}
      {/* {renderEditEventSection()}
      {renderConfirmSection()}
      {renderCancelSection()} */}
      {showLoader && <BackDropLoader open={showLoader} />}
    </Paper>
  );
};

export default CrudTable;
