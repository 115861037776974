import { MdPhoneCallback } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { useState } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { useMediaQuery } from "react-responsive";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { useAppDataContext } from "hooks/useappdatacontext";

const Contact = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0, x: "-100%" }, // Initially off-screen to the left
    visible: {
      opacity: 1,
      x: 0, // Move to its final position
      transition: {
        staggerChildren: 0.2,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const mobileContainerVariants = {
    hidden: { opacity: 0, x: "-50%" }, // Smaller distance on mobile
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.2,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const { showToast } = useAppDataContext();

  const [feedbackData, setFeedbackData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [feedbackDataErrors, setFeedBackDataErrors] = useState({
    nameError: "",
    emailError: "",
    messageError: "",
  });

  const [loading, setLoading] = useState(false);

  const validateFeedbackData = () => {
    const errors = {
      nameError: "",
      emailError: "",
      messageError: "",
    };

    if (feedbackData.name.trim() === "") {
      errors.nameError = "Name is required";
    }
    const emailRegex =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|outlook\.com|hotmail\.com|yahoo\.com)$/;
    if (feedbackData.email.trim() === "") {
      errors.emailError = "Email is required";
    } else if (!emailRegex.test(feedbackData.email)) {
      errors.emailError = "Please enter a valid email address";
    }
    if (feedbackData.message.trim() === "") {
      errors.messageError = "Message is required";
    }

    setFeedBackDataErrors(errors);
    return errors;
  };

  const onChangeMessageHandler = (e) => {
    const { name, value } = e.target;
    setFeedbackData({ ...feedbackData, [name]: value });
  };

  const submitMessageHandler = async (e) => {
    e.preventDefault();
    const errors = validateFeedbackData();
    if (!errors.nameError && !errors.emailError && !errors.messageError) {
      try {
        setLoading(true);
        // console.log("Sucess");
        const response = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/contact/`,
          feedbackData
        );

        if (response.status === 200) {
          setLoading(false);
          setFeedbackData({
            name: "",
            email: "",
            message: "",
          });
          showToast.success("Message sent successfully!");
          alert("Message sent successfully!");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  return (
    <div id="support">
      <div
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        className="container"
      >
        <motion.h2
          variants={itemVariants}
          style={{ textAlign: "center" }}
          class="features-heading"
        >
          Get In Touch With Us
        </motion.h2>
        <motion.p
          variants={itemVariants}
          style={{ marginBottom: "4rem", textAlign: "center" }}
          class="service-section-text"
        >
          We're here to help you with any questions or concerns. Reach out to us
          and we'll get back to you as soon as possible!
        </motion.p>
        <div className="row">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={isMobile ? mobileContainerVariants : containerVariants}
            className="col bg-primary rounded p-md-5 p-3"
          >
            <div
              style={{ borderRadius: "15px" }}
              className="d-flex justify-content-start align-items-start contact-us-details-contaner "
            >
              <div className="dashed-circle rounded-circle me-3">
                <MdPhoneCallback className="child" size={30} />
              </div>
              <div className="text-white ">
                <h4 style={{ marginTop: "12px" }} className="section-matter">
                  Call Us
                </h4>
                <h3 className="fw-bold serif contact-link section-matter">
                  +91 9030908181
                </h3>
              </div>
            </div>
            <hr />
            <div
              style={{ borderRadius: "15px" }}
              className="d-flex justify-content-start align-items-start  "
            >
              <div className="dashed-circle rounded-circle me-3">
                <IoIosMail className="child text-white" size={30} />
              </div>
              <div className="text-white section-matter">
                <h4 style={{ marginTop: "12px" }} className="section-matter">
                  Mail Us
                </h4>
                <h3 className="section-matter fw-bold serif contact-link">
                  support@eventsonlife.com
                </h3>
              </div>
            </div>
            <hr />
            <div
              style={{ borderRadius: "15px" }}
              className="d-flex justify-content-start align-items-start  "
            >
              <div className="dashed-circle rounded-circle me-3">
                <FaLocationDot className="child text-white " size={30} />
              </div>
              <div className="text-white section-matter">
                <h4 style={{ marginTop: "12px" }} className="section-matter">
                  Location
                </h4>
                <a
                  href="https://maps.app.goo.gl/P3KCMAK1xwfQdVLr5"
                  className="fw-bold serif contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  656, Ayyappa Society, Madhapur
                  <br />
                  Hyderabad 500018
                </a>
              </div>
            </div>
            <hr />
            <div
              style={{ borderRadius: "15px" }}
              className="d-flex justify-content-start align-items-start  "
            >
              <div className="dashed-circle rounded-circle me-3">
                <FaWhatsapp className="child text-white" size={30} />
              </div>
              <div className="text-white section-matter">
                <h4 style={{ marginTop: "12px" }} className="section-matter">
                  Whats App
                </h4>
                <h4
                  className="section-matter fw-bold serif contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +91 9030908181
                </h4>
              </div>
            </div>
          </motion.div>
          <form
            onSubmit={submitMessageHandler}
            className="col p-md-5 p-3 text-secondary"
          >
            <label htmlFor="name" className="label h3 fw-bold">
              Your Name*
            </label>
            <br />
            <input
              id="name"
              name="name"
              value={feedbackData.name}
              onChange={onChangeMessageHandler}
              className="form-control mt-2"
              type="text"
              placeholder="Your Name"
            />
            {feedbackDataErrors.nameError && (
              <p className="text-danger mb-0">{feedbackDataErrors.nameError}</p>
            )}
            <br />
            <label htmlFor="email" className="label h3 fw-bold">
              Your Mail*
            </label>
            <br />
            <input
              id="email"
              name="email"
              value={feedbackData.email}
              onChange={onChangeMessageHandler}
              className=" form-control mt-2"
              type="text"
              placeholder="Your Mail"
            />
            {feedbackDataErrors.emailError && (
              <p className="text-danger mb-0">
                {feedbackDataErrors.emailError}
              </p>
            )}
            <br />
            <label html="message" className="label h3 fw-bold">
              Write Message*
            </label>
            <br />
            <textarea
              style={{
                maxHeight: "100px",
              }}
              id="message"
              name="message"
              value={feedbackData.message}
              onChange={onChangeMessageHandler}
              className="form-control mt-2"
              type="text"
              placeholder="Write Your Message here..."
            />
            {feedbackDataErrors.messageError && (
              <p className="text-danger mb-0">
                {feedbackDataErrors.messageError}
              </p>
            )}
            <br />
            {loading ? (
              <buton
                className="fs-4 btn btn-primary p-3 form-control d-flex justify-content-center aligrn-items-center"
                disable
              >
                <TailSpin color="#00BFFF" height={30} width={30} />
              </buton>
            ) : (
              <button
                type="submit"
                className="fs-4 btn btn-primary p-3 form-control"
              >
                Send Message
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
